import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

export const TwoColumnsPaneView = ({ title, leftPane, rightPane, className }) => {
  const rootClassName = `two-columns-pane-view ${className}`;
  return (
    <div className={rootClassName}>
      {title ? <div className="two-columns-pane-view-title">{title}</div> : ''}
      <div className="two-columns-pane-view-container">
        <div className="two-columns-pane-view-left-pane">{leftPane}</div>
        <div className="two-columns-pane-view-right-pane">{rightPane}</div>
      </div>
    </div>
  );
};

TwoColumnsPaneView.defaultProps = {
  className: '',
  title: null
};

TwoColumnsPaneView.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  leftPane: PropTypes.node.isRequired,
  rightPane: PropTypes.node.isRequired
};
