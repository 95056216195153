import React from 'react';
import Style from './css/Overview.module.css';

export default function Overview({ leftComponent, rightComponent }) {
  return (
    <div className={Style.container}>
      <div className={Style.left_component}>{leftComponent}</div>
      <div className={Style.right_component}>{rightComponent}</div>
    </div>
  );
}
