import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { ColorPicker } from '../../../../../components/color-picker';

export const AddPinProcedure = ({
  x,
  y,
  name,
  id,
  onPinAdded,
  onColorPickCancelled,
  requestTicket
}) => {
  const [showColorPicker, setShowColorPicker] = useState(!!requestTicket);

  // no point putting this in useCallback, since the callback depends on all arguments.
  //  It makes sense to let this render again if the component has to be re-render.
  const onSelectColor = (color) => {
    setShowColorPicker(false);
    onPinAdded({ x, y, name, color, id });
  };

  const onCancelSelectColor = () => {
    setShowColorPicker(false);
    onColorPickCancelled({ x, y, name, id });
  };

  return (
    <Fragment>
      {showColorPicker && (
        <ColorPicker
          show={!!showColorPicker}
          offsetLeft={x}
          offsetTop={y}
          onSave={onSelectColor}
          onCancel={onCancelSelectColor}
        />
      )}
    </Fragment>
  );
};

AddPinProcedure.defaultProps = {
  onPinAdded: () => {},
  onColorPickCancelled: () => {},
  requestTicket: null
};

AddPinProcedure.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onPinAdded: PropTypes.func,
  onColorPickCancelled: PropTypes.func,
  requestTicket: PropTypes.string
};
