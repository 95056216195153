import { AppsConfigurationDataProvider } from './static-configuration-data-provider';

export class XemelgoApp {
  constructor(config) {
    let configObj = config;
    if (typeof config === 'string') {
      configObj = JSON.parse(config);
    }

    this.id = configObj.id;
    this.displayName = configObj.displayName;
    this.href = configObj.href;
    this.imageName = configObj.imageName;
    this.config = configObj;
  }

  static parse(appId) {
    const config = AppsConfigurationDataProvider.getAppConfiguration(appId);
    return new XemelgoApp(config);
  }

  getId() {
    return this.id;
  }

  getDisplayName() {
    return this.displayName;
  }

  getRelativeLink() {
    return this.href;
  }

  getImageName() {
    return this.imageName;
  }
}
