import { importAllImages } from '../common/Utilities';

const images = importAllImages(require.context('../img', false, /\.(png)$/));

const appsConfigurationMap = {
  inventoryRequest: {
    href: '/apps/inventory-request',
    displayName: 'Inventory Request',
    imageSrc: images['track_icon.png'],
    imageSrcAlt: 'tracked-objects',
    imageInfo: {
      src: images['track_icon.png'],
      srcAlt: 'tracked-objects'
    },
    imageName: 'track_icon.png'
  },
  floorPlans: {
    href: '/apps/floor-plans',
    displayName: 'Floor Plan',
    imageSrc: images['track_icon.png'],
    // imageSrcAlt: 'tracked-objects',
    // imageInfo: {
    //   // src: images['orion_logo.png'],
    //   // srcAlt: 'orion_logo_alt'
    //   src: images['track_icon.png'],
    //   srcAlt: 'tracked-objects'
    // },
    imageName: 'orion_logo.png'
  },
  myFacility: {
    href: '/apps/floor-plans',
    displayName: 'Floor Plan',
    imageSrc: images['track_icon.png'],
    // imageSrcAlt: 'tracked-objects',
    // imageInfo: {
    //   // src: images['orion_logo.png'],
    //   // srcAlt: 'orion_logo_alt'
    //   src: images['track_icon.png'],
    //   srcAlt: 'tracked-objects'
    // },
    imageName: 'orion_logo.png'
  }
};

export const AppsConfigurationDataProvider = {
  getAppsConfigurations() {
    return Object.keys(appsConfigurationMap).map((appId) => {
      const appConfig = appsConfigurationMap[appId];
      return {
        id: appId,
        ...appConfig
      };
    });
  },

  getAppConfiguration(appId) {
    const config = appsConfigurationMap[appId];
    if (!config) {
      return null;
    }

    return {
      id: appId,
      ...config
    };
  }
};
