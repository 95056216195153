export class UserProfile {
  constructor(profile) {
    let profileObj = profile;
    if (typeof profile === 'string') {
      profileObj = JSON.parse(profile);
    }

    this.profile = profileObj;
    const context = this;
    Object.keys(profileObj).forEach((key) => {
      context[key] = profileObj[key];
    });
  }

  static parse(authenticatedUserInfo) {
    const { username, userGroups, isSuperAdmin, attributes } = authenticatedUserInfo;
    const {
      sub: userId,
      'custom:tenantId': tenantId,
      family_name: lastName,
      given_name: firstName,
      email,
      phone_number: phoneNumber
    } = attributes;

    const profile = {
      userId,
      username,
      tenantId,
      lastName,
      firstName,
      email,
      phoneNumber,
      isSuperAdmin,
      userGroups,
      attributes
    };

    return new UserProfile(profile);
  }

  getUsername() {
    return this.username;
  }

  getUserId() {
    return this.userId;
  }

  getFirstName() {
    return this.firstName;
  }

  getLastName() {
    return this.lastName;
  }

  getTenantId() {
    return this.tenantId;
  }

  getEmail() {
    return this.email;
  }

  isUserSuperAdmin() {
    return this.isSuperAdmin;
  }

  getUserGroups() {
    return this.userGroups;
  }

  getAttributes() {
    return this.attributes;
  }

  getPhoneNumber() {
    return this.phoneNumber;
  }

  toJson() {
    return JSON.stringify(this.profile);
  }
}
