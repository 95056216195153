import { createStore } from 'react-hookstore';

export const LoginStateAction = {
  IS_NEW_PASSWORD_STATE: 'IS_NEW_PASSWORD_STATE',
  IS_LOGIN_STATE: 'IS_LOGIN_STATE',
  IS_FORGOT_PASSWORD_STATE: 'IS_FORGOT_PASSWORD_STATE',
  IS_FORGOT_PASSWORD_VERIFICATION_STATE: 'IS_FORGOT_PASSWORD_VERIFICATION_STATE'
};

export const initialLoginState = {
  login: true,
  newPassword: false,
  forgotPassword: false,
  forgotPasswordVerification: false
};

export const loggingUserStore = createStore('loggingUserStore', null);

export const loginStateStore = createStore(
  'loginStateStore',
  initialLoginState,
  (state, action) => {
    switch (action.type) {
      case LoginStateAction.IS_LOGIN_STATE:
        return {
          ...initialLoginState,
          ...{
            login: true
          }
        };
      case LoginStateAction.IS_FORGOT_PASSWORD_STATE:
        return {
          ...initialLoginState,
          ...{
            login: false,
            forgotPassword: true,
            forgotPasswordVerification: false,
            newPassword: false
          }
        };
      case LoginStateAction.IS_FORGOT_PASSWORD_VERIFICATION_STATE:
        return {
          ...initialLoginState,
          ...{
            login: false,
            forgotPassword: false,
            forgotPasswordVerification: true,
            newPassword: false
          }
        };
      case LoginStateAction.IS_NEW_PASSWORD_STATE:
        return {
          ...initialLoginState,
          ...{
            login: false,
            forgotPassword: false,
            forgotPasswordVerification: false,
            newPassword: true
          }
        };
      default:
        return state;
    }
  }
);
