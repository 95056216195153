import React from 'react';
import PropTypes from 'prop-types';

export const GpsPinHeadSvg = ({ color, height, width, className }) => {
  return (
    <svg
      className={className}
      height={height}
      width={width}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
      enableBackground="new 0 0 48 48"
      xmlSpace="preserve"
    >
      <path d="M24,8c-6.1,0-11,4.9-11,11s11,21,11,21s11-14.9,11-21S30.1,8,24,8z M24,24c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5  S26.8,24,24,24z" />
    </svg>
  );
};

GpsPinHeadSvg.defaultProps = {
  color: '#000000',
  height: '100px',
  width: '100px',
  className: ''
};

GpsPinHeadSvg.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string
};
