import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

export const TextLink = ({ label, onClick, className }) => {
  return (
    // disable es rule check against no onClick on span
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span onClick={onClick} className={`text-link ${className}`}>
      {label}
    </span>
  );
};

TextLink.defaultProps = {
  onClick: () => {},
  className: ''
};

TextLink.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string
};
