import React, { Fragment, Component } from 'react';
import Divider from '@material-ui/core/Divider';
import { FormControl } from 'react-bootstrap';
import { Card, CardBody } from 'mdbreact';
import SearchAndSubmit from '../SearchAndSubmit';
import './NewRequest.css';
import _ from 'lodash';
import MoreImage from '../../img/More_blue.png';

export default class NewRequest extends Component {
  constructor(props) {
    super(props);

    this.selectItem = this.selectItem.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.getUserSearchAndSubmit = this.getUserSearchAndSubmit.bind(this);
    this.getDefaultInput = this.getDefaultInput.bind(this);
    this.submitButton = this.submitButton.bind(this);
    this.cancelRequest = this.cancelRequest.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.getEditInputStyle = this.getEditInputStyle.bind(this);
    this.getTextAreaInput = this.getTextAreaInput.bind(this);
    this.buildItemSchema = this.buildItemSchema.bind(this);
    this.buildUsersSchema = this.buildUsersSchema.bind(this);

    this.selectVendor = this.selectVendor.bind(this);
    this.getVendorSearchAndSubmit = this.getVendorSearchAndSubmit.bind(this);
    this.buildVendorsSchema = this.buildVendorsSchema.bind(this);
    this.handleVendorInput = this.handleVendorInput.bind(this);

    this.state = {
      searchItem: undefined,
      showSearchList: true,
      selectPastOrder: true
    };
  }

  async componentDidMount() {
    const schema = [
      {
        name: 'Order Number',
        param_name: 'order_number',
        validation(input) {
          return {
            error: !input || input === '',
            message: 'Please provide an order number'
          };
        },
        input_type: this.getDefaultInput,
        input_type_length: 'm'
      },
      {
        name: 'Package Size',
        param_name: 'size',
        validation(input) {
          return {
            error: !input || input === '',
            message: 'Please provide a size'
          };
        },
        input_type: this.getDefaultInput,
        input_type_length: 's',
        editable: true
      },
      {
        name: 'Vendor',
        param_name: 'vendor',
        validation(input) {
          return {
            error: !input || input === '',
            message: 'Please provide the vendor name'
          };
        },
        input_type: this.getVendorSearchAndSubmit,
        input_type_length: 'm'
      },
      {
        name: 'Item Name',
        param_name: 'stock_item_name',
        validation(input) {
          return {
            error: !input || input === '',
            message: 'Please provide an item name'
          };
        },
        input_type: this.getDefaultInput,
        input_type_length: 'm'
      },
      {
        name: 'Quantity',
        param_name: 'quantity',
        validation(input) {
          return {
            error: !input || input === '' || !/^[0-9]*$/.test(input),
            message: 'Please provide a quantity in numerical value'
          };
        },
        input_type: this.getDefaultInput,
        input_type_length: 's'
      },
      {
        name: "Requester's Name",
        param_name: 'user_id',
        validation(input) {
          return {
            error: !input || input === '',
            message: 'Please provide your name'
          };
        },
        input_type: this.getUserSearchAndSubmit,
        input_type_length: 'm'
      }
    ];

    if (this.hasOptionalColumnHeader('Cost')) {
      schema.push({
        name: 'Cost ($)',
        param_name: 'cost',
        validation(input) {
          return {
            error: !input || input === '' || !/^[0-9]*(\.[0-9]{1,2}){0,1}$/.test(input),
            message: 'Please provide a cost up to 2 decimal places without $ sign'
          };
        },
        input_type: this.getDefaultInput,
        input_type_length: 's'
      });
    }

    schema.push({
      name: 'Comments',
      param_name: 'comments',
      input_type: this.getTextAreaInput,
      input_type_length: 'm',
      placeholder: 'Add optional comments'
    });

    const formContents = {};

    schema.forEach(schemaItem => {
      formContents[schemaItem.param_name] = null;
    });

    this.setState({
      schema,
      formContents,
      isValid: formContents,
      itemSchema: this.buildItemSchema(),
      usersSchema: this.buildUsersSchema(),
      vendorsSchema: this.buildVendorsSchema()
    });
  }

  hasOptionalColumnHeader = columnHeader => {
    const optionalColumnHeader = this.props.optionalColumnHeader;
    return optionalColumnHeader && optionalColumnHeader.indexOf(columnHeader) !== -1;
  };

  getDefaultInput(schemaItem, searchItem) {
    const inputValue = searchItem && searchItem[schemaItem.param_name];
    return (
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <FormControl
          className={
            schemaItem.editable
              ? this.getEditInputStyle(schemaItem)
              : inputValue
              ? 'form-input-field-readonly'
              : this.getEditInputStyle(schemaItem)
          }
          type="text"
          readOnly={schemaItem.editable ? false : inputValue}
          value={
            schemaItem.editable
              ? this.state.formContents[schemaItem.param_name]
              : inputValue || this.state.formContents[schemaItem.param_name] || ''
          }
          onChange={e => {
            this.setState({
              formContents: {
                ...this.state.formContents,
                [schemaItem.param_name]: e.target.value
              }
            });
          }}
        />
      </form>
    );
  }

  getTextAreaInput(schemaItem, searchItem) {
    return (
      <textarea
        autoFocus={false}
        rows="4"
        placeholder={schemaItem.placeholder}
        className={`text-area-input form-input-field-editable-${schemaItem.input_type_length}`}
        value={this.state.formContents[schemaItem.param_name] || ''}
        onChange={e => {
          this.setState({
            formContents: {
              ...this.state.formContents,
              [schemaItem.param_name]: e.target.value
            }
          });
        }}
      />
    );
  }

  getEditInputStyle(schemaItem) {
    let style = `form-input-field-editable-${schemaItem.input_type_length}`;
    if (this.state.isValid[schemaItem.param_name].error) {
      style += ' input-error-form';
    }
    return style;
  }

  selectUser(selectedUser) {
    this.setState({
      formContents: {
        ...this.state.formContents,
        user_id: selectedUser
      },
      isValid: {
        ...this.state.isValid,
        user_id: false
      }
    });
  }

  getUserSearchAndSubmit(schemaItem) {
    const { formContents, usersSchema } = this.state;
    const users = this.props.users;
    return (
      <div className={this.getEditInputStyle(schemaItem)}>
        <SearchAndSubmit
          value={users[formContents.user_id] ? users[formContents.user_id].fullname : ''}
          searchMap={usersSchema}
          selectItem={this.selectUser}
          searchStyle="fixed"
          showSearchList
        />
      </div>
    );
  }

  buildUsersSchema() {
    const suggestionToQueryParam = [];
    const users = this.props.users;

    for (const key in users) {
      const object = users[key];

      suggestionToQueryParam.push({
        id: key,
        key: [object.fullname, object.username],
        displayString: object.fullname
      });
    }

    return suggestionToQueryParam;
  }

  selectVendor(selectedVendor) {
    const vendor = this.props.vendors[selectedVendor];
    this.setState({
      formContents: {
        ...this.state.formContents,
        vendor
      },
      isValid: {
        ...this.state.isValid,
        vendor: false
      }
    });
  }

  handleVendorInput(inputValue) {
    if (this.state.formContents.vendor !== inputValue) {
      this.setState({
        formContents: {
          ...this.state.formContents,
          vendor: inputValue
        },
        isValid: {
          ...this.state.isValid,
          vendor: false
        }
      });
    }
    return null;
  }

  getVendorSearchAndSubmit(schemaItem) {
    const { searchItem, selectPastOrder, formContents, vendorsSchema, showSearchList } = this.state;
    const inputValue = searchItem && searchItem[schemaItem.param_name];

    if (selectPastOrder && formContents.vendor) {
      return (
        <form
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <FormControl
            className={
              schemaItem.editable
                ? this.getEditInputStyle(schemaItem)
                : inputValue
                ? 'form-input-field-readonly'
                : this.getEditInputStyle(schemaItem)
            }
            type="text"
            readOnly={inputValue}
            value={
              schemaItem.editable
                ? formContents[schemaItem.param_name]
                : inputValue || formContents[schemaItem.param_name] || ''
            }
          />
        </form>
      );
    }
    // otherwise take in user input with the vendor-suggestion list
    return (
      <div
        className={this.getEditInputStyle(schemaItem)}
        onFocus={() => {
          this.setState({ showSearchList: true, selectPastOrder: false });
        }}
      >
        <SearchAndSubmit
          value={formContents.vendor}
          searchMap={vendorsSchema}
          selectItem={this.selectVendor}
          searchStyle="fixed"
          showSearchList={showSearchList}
          handleInputChange={value => {
            this.handleVendorInput(value);
          }}
          name="Vendor"
        />
      </div>
    );
  }

  buildVendorsSchema() {
    const suggestionToQueryParam = [];
    const vendors = this.props.vendors;

    for (const key in vendors) {
      const object = vendors[key];

      suggestionToQueryParam.push({
        id: key,
        key: object,
        displayString: object
      });
    }

    return suggestionToQueryParam;
  }

  buildItemSchema() {
    const suggestionToQueryParam = [];
    const stockItems = this.props.stockItems;

    for (const key in stockItems) {
      const object = stockItems[key];

      suggestionToQueryParam.push({
        id: object.stock_item_id,
        key: [object.vendor, object.order_number, object.stock_item_name, object.size],
        displayString: this.getDisplayString(object)
      });
    }

    return suggestionToQueryParam;
  }

  getDisplayString(item) {
    let result = '';

    if (item.stock_item_name) {
      result += `Item: <span class='list-group-item-highlight'>${item.stock_item_name}</span> `;
    }
    if (item.order_number) {
      result += `Order Number: <span class='list-group-item-highlight'>${item.order_number}</span> `;
    }
    if (item.size) {
      result += `Package Size: <span class='list-group-item-highlight'>${item.size}</span> `;
    }
    if (item.vendor) {
      result += `Vendor: <span class='list-group-item-highlight'>${item.vendor}</span> `;
    }

    return result;
  }

  selectItem(selectedItem) {
    const item = this.props.stockItems.find(item => {
      if (item.stock_item_id === selectedItem) {
        return item;
      }
      return undefined;
    });

    const finalValue = item || selectedItem;

    const contents = _.cloneDeep(this.state.formContents);
    const isValid = _.cloneDeep(this.state.isValid);

    this.state.schema.forEach(schemaItem => {
      contents[schemaItem.param_name] = finalValue[schemaItem.param_name];
      isValid[schemaItem.param_name] = false;
    });

    this.setState({
      searchItem: finalValue,
      formContents: contents,
      isValid,
      selectPastOrder: true
    });
  }

  validateInput() {
    let error = false;
    const { schema, formContents } = this.state;
    const isValid = _.cloneDeep(this.state.isValid);

    schema.forEach(schemaItem => {
      if (schemaItem.validation) {
        const validationResult = schemaItem.validation(formContents[schemaItem.param_name]);
        isValid[schemaItem.param_name] = validationResult;

        if (validationResult.error) {
          error = true;
        }
      }
    });

    this.setState({
      isValid
    });

    return error;
  }

  async submitButton() {
    if (!this.validateInput()) {
      const { searchItem, formContents } = this.state;
      const { addStockItem, updateStockItem, addRequests, cancelRequestFn } = this.props;
      let stockItemId;
      let isNewItem = false;
      if (!searchItem.stock_item_id) {
        const payload = {
          vendor: formContents.vendor,
          order_number: formContents.order_number,
          size: formContents.size,
          stock_item_name: formContents.stock_item_name
        };

        if (this.hasOptionalColumnHeader('Cost')) {
          payload.cost = Number(this.state.formContents.cost);
        }

        stockItemId = await addStockItem(payload);
        isNewItem = true;
      }

      const payload = {
        stock_item_id: stockItemId || searchItem.stock_item_id,
        restock_request_details: [
          {
            user_id: formContents.user_id,
            restock_requested_timestamp: Date.now(),
            restock_last_updated_timestamp: Date.now(),
            quantity: parseInt(this.state.formContents.quantity)
          }
        ],
        vendor: formContents.vendor,
        order_number: formContents.order_number,
        stock_item_name: formContents.stock_item_name,
        size: formContents.size,
        comment: formContents.comments || null,
        status: 'New'
      };

      // added this for Cost
      if (this.hasOptionalColumnHeader('Cost')) {
        payload.cost = Number(this.state.formContents.cost);
      }

      const form = _.cloneDeep(this.state.formContents);
      const oldStockItem = _.cloneDeep(this.state.searchItem);

      if (!isNewItem) {
        updateStockItem(form, oldStockItem);
      }
      addRequests(payload);
      cancelRequestFn();
    }
  }

  cancelRequest() {
    const formContents = {};
    this.state.schema.forEach(schemaItem => {
      formContents[schemaItem.param_name] = null;
    });

    this.setState({
      searchItem: '',
      selectItem: undefined,
      formContents,
      isValid: formContents
    });

    this.props.cancelRequestFn();
  }

  render() {
    const { schema, isValid, searchItem, itemSchema } = this.state;
    const submitForm = item => {
      return (
        <Fragment>
          <Divider />
          <div
            className="submit-form"
            onClick={event => {
              event.target.name === 'Vendor'
                ? this.setState({ showSearchList: true })
                : this.setState({ showSearchList: false });
            }}
          >
            {schema.map(schemaItem => {
              return (
                <div className="input-div" key={schemaItem.name}>
                  <p className="form-input-label">{schemaItem.name}</p>
                  {schemaItem.input_type(schemaItem, item)}
                  {isValid[schemaItem.param_name].error && (
                    <p className="input-error-message">{isValid[schemaItem.param_name].message}</p>
                  )}
                </div>
              );
            })}
          </div>
          <div className="horizontal-center-align">
            <button className="request-submit-button" onClick={this.submitButton}>
              Submit Request
            </button>
          </div>
        </Fragment>
      );
    };

    const getPostAction = fn => {
      return (
        <Fragment>
          Don't see what you're looking for?
          <button
            className="add-new-item"
            onClick={() => {
              fn();
              this.selectItem({
                stock_item_id: '',
                vendor: '',
                order_number: '',
                size: '',
                stock_item_name: ''
              });
            }}
          >
            <div>Add a new Item</div>
          </button>
        </Fragment>
      );
    };
    return (
      <Card className="new-request-card">
        <CardBody id="main-card-body">
          <div className="title">
            <h2>New Request</h2>
          </div>
          <Divider />
          <div className="back-button">
            <p onClick={() => this.cancelRequest()} className="back-button-text">
              <img src={MoreImage} alt="more" className="back-button-icon" /> Cancel Request
            </p>
          </div>
          <div className="search-form">
            <p className="search-form-label">Enter item name or order number.</p>
            {
              <SearchAndSubmit
                selectItem={this.selectItem}
                value={searchItem && searchItem.stock_item_name}
                postAction={getPostAction}
                searchStyle="fixed"
                searchMap={itemSchema}
                showSearchList
              />
            }
          </div>
          {searchItem && submitForm(searchItem)}
        </CardBody>
      </Card>
    );
  }
}
