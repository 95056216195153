import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalForm } from '../../../../components/modal-form';
import { DeleteResourceFormFooter } from './delete-resource-form-footer';
import Loading from '../../../../components/loading/LoadingWord';

export const DeleteResourceForm = ({ show, resource, onSubmit, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const onSubmitCallback = useCallback(() => {
    setLoading(true);
    onSubmit(resource.id);
  }, [onSubmit, resource]);

  return (
    <Fragment>
      {loading && <Loading />}
      <ModalForm
        show={show}
        title={`Delete ${resource.name}?`}
        body={`You're about to delete ${resource.name}, are you sure you want to do this?`}
        footer={<DeleteResourceFormFooter onSubmit={onSubmitCallback} onCancel={onCancel} />}
      />
    </Fragment>
  );
};

DeleteResourceForm.defaultProps = {
  show: false,
  onSubmit: () => {},
  onCancel: () => {}
};

DeleteResourceForm.propTypes = {
  resource: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};
