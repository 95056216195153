import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

export const ColorBox = ({ onClick, color }) => {
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  return <div className="color-box" onClick={onClick} style={{ backgroundColor: color }} />;
};

ColorBox.defaultProps = {
  onClick: () => {},
  color: '#000000'
};

ColorBox.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string
};
