import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useStore } from 'react-hookstore';
import PropTypes from 'prop-types';
import { userProfileStore } from '../state-managements/stores/user-profile-store';
import { LocalCacheService } from '../services/local-cache-service';
import { configStore } from '../state-managements/stores/config-store';

/**
 * Check user profile to determine if user is authorized to access to url
 * @param userProfile { UserProfile }
 * @param authorizingRoles
 * @returns {boolean}
 */
const isAuthorizedToAccess = (userProfile, authorizingRoles) => {
  if (!authorizingRoles || authorizingRoles.length === 0) {
    return true;
  }

  const attributes = userProfile.getAttributes();
  const userGroups = userProfile.getUserGroups() || [];

  const authorized = authorizingRoles.find((role) => {
    // we perform checking attributes for given role due to the way we initially assign admin role.
    // we may deprecate this approach in the future and move toward using userGroups insteads
    return attributes[role] || userGroups.includes(role);
  });
  return !!authorized;
};

/*
 * - component: component to render/page to load
 * - Authorized roles: list of strings that are used as conditions to check
 *   if a user clicking on the app is authorized to view the app/URL.
 *
 */
const AuthorizedProcessAppRoute = ({
  component: Component,
  hide_header_searchBar: hideSearchBar,
  path,
  authorizedRoles
}) => {
  const [userProfile, setUserProfile] = useStore(userProfileStore);
  const [, setUserConfig] = useStore(configStore);

  /** @type {UserProfile} */
  let currentUserProfile = userProfile;
  if (!userProfile) {
    /*
    This happens when either user has yet signed in, or when user refreshes the page.
    When the page is refreshed, the state tree will be reinitialized to null. Therefore,
    we will attempt to retrieve everything from cache.
     */
    const cachedUserProfile = LocalCacheService.loadUserProfile();
    currentUserProfile = cachedUserProfile;
    if (cachedUserProfile) {
      setUserProfile(cachedUserProfile);
      sessionStorage.setItem('sessionInfo', JSON.stringify(cachedUserProfile));
    }

    const cachedUserConfig = LocalCacheService.loadUserConfig();
    if (cachedUserConfig) {
      setUserConfig(cachedUserConfig);
    }

    // ClientConfigurationService.getConfig().then((customerConfig) => {
    //   const rawConfig = customerConfig.getRawData();
    //   const userConfig = UserConfiguration.parse(rawConfig);
    //   LocalCacheService.saveUserConfig(userConfig);
    //   setUserConfig(userConfig);
    // });
  }

  const loggedIn = !!currentUserProfile;
  const isAuthorized = isAuthorizedToAccess(currentUserProfile, authorizedRoles);

  if (loggedIn) {
    // Make sure fcWidget.init is included before setting these values
    // To set unique user id in your system when it is available
    //
    // 	<!-- Initializing Chat Widget -->
    window.fcWidget.init({
      token: '8b71db32-33e9-4423-916d-966821e989a0',
      host: 'https://wchat.freshchat.com',
      externalId: currentUserProfile.getUsername()
    });
    window.fcWidget.user.get(function(resp) {
      const status = resp && resp.status;
      // Status 200 is from Freschat API docs -- It means that there is a user registered using the chat
      if (status !== 200) {
        window.fcWidget.user.setProperties({
          firstName: currentUserProfile.getFirstName(), // user's first name
          lastName: currentUserProfile.getLastName(), // user's last name
          email: currentUserProfile.getEmail(), // user's email address
          phone: currentUserProfile.getPhoneNumber()
        });
        window.fcWidget.on('user:created', function(onCreatedResp) {
          const onCreatedRespStatus = onCreatedResp && onCreatedResp.status;
          // TODO: this part of initalization will check if the user exists and if so, we use restoreID to initialize
          // chat with previous conversation if logging on to the web app via different machines
          if (onCreatedRespStatus === 200) {
            // Update restoreId in your database
          }
        });
      }
    });
  }
  return (
    <Route
      render={(matchProps) => {
        // redirect to login page if not logged in
        if (!loggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { referrer: path }
              }}
            />
          );
        }

        if (!isAuthorized) {
          return (
            <Redirect
              to={{
                pathname: '/error/authorized',
                state: { referrer: path }
              }}
            />
          );
        }

        return <Component {...matchProps} />;
      }}
    />
  );
};

AuthorizedProcessAppRoute.defaultProps = {
  hide_header_searchBar: false,
  authorizedRoles: null
};

AuthorizedProcessAppRoute.propTypes = {
  component: PropTypes.func.isRequired,
  hide_header_searchBar: PropTypes.bool,
  path: PropTypes.string.isRequired,
  authorizedRoles: PropTypes.arrayOf(PropTypes.string)
};

export default AuthorizedProcessAppRoute;
