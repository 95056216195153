import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalForm } from '../../../../components/modal-form';
import { FormFooter } from './footer';
import { EditFormBody } from './edit-form-body';
import { FeatureConfigurationProvider } from '../../../../domains/feature-configuration-provider';

const FeatureId = 'editResource';
export const EditResourceForm = ({
  configuration,
  modelId,
  show,
  providedArgument,
  resource,
  onCancel,
  onSave
}) => {
  const [properties, setProperties] = useState([]);
  const [resourceName, setResourceName] = useState(modelId);
  const [submitTicket, setSubmitTicket] = useState(null);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configuration || !modelId || !resource || !providedArgument) {
      return cancelCallback;
    }

    const configProvider = FeatureConfigurationProvider.parse(FeatureId, configuration);
    const propertyOrders = configProvider.getValue('propertyOrders', 'array', []);
    const modelConfigProvider = configProvider.getModel(modelId);
    const propertyMap = modelConfigProvider.getPropertyMap();

    const props = propertyOrders.map((propertyId) => {
      const property = propertyMap[propertyId];
      const { displayName, options, __updatable: editable, optionsProvided, name } = property;

      let input = options;
      if (optionsProvided) {
        input = providedArgument[propertyId];
      }

      return {
        input,
        name: name || propertyId,
        displayName,
        editable
      };
    });

    if (!cancelled) {
      setResourceName(resource.name);
      setProperties(props);
    }

    return cancelCallback;
  }, [configuration, modelId, resource, providedArgument]);

  const onSubmitButtonClicked = useCallback(() => {
    setSubmitTicket(Date.now().toString());
  }, []);

  return (
    <ModalForm
      show={show}
      title={`Editing ${resourceName}`}
      body={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <EditFormBody
          properties={properties}
          resource={resource}
          onSubmit={onSave}
          submitTicket={submitTicket}
        />
      }
      size="md"
      footer={<FormFooter onCancel={onCancel} onSave={onSubmitButtonClicked} />}
    />
  );
};

EditResourceForm.defaultProps = {
  show: false,
  providedArgument: {},
  onCancel: () => {},
  onSave: () => {}
};

EditResourceForm.propTypes = {
  configuration: PropTypes.shape({
    modelMap: PropTypes.objectOf(
      PropTypes.shape({
        category: PropTypes.shape({
          name: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired
        }),
        parentLocationCategory: PropTypes.shape({
          name: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired
        }),
        propertyOrders: PropTypes.arrayOf(PropTypes.string),
        properties: PropTypes.objectOf(
          PropTypes.shape({
            __addable: PropTypes.bool,
            __optional: PropTypes.bool,
            __optionallyDependsOn: PropTypes.arrayOf(PropTypes.string),
            displayName: PropTypes.string,
            input: PropTypes.oneOfType([
              PropTypes.bool,
              PropTypes.string,
              PropTypes.arrayOf(
                PropTypes.shape({
                  key: PropTypes.string.isRequired,
                  value: PropTypes.string.isRequired
                })
              )
            ])
          })
        )
      })
    )
  }).isRequired,
  modelId: PropTypes.string.isRequired,
  providedArgument: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string
        })
      )
    ])
  ),
  resource: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  onCancel: PropTypes.func,
  show: PropTypes.bool,
  onSave: PropTypes.func
};
