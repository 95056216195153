import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../../../components/div';
import './style.css';
import { XButton } from '../../../../components/xemelgo-button/x-button';
import { FeatureConfigurationProvider } from '../../../../domains/feature-configuration-provider';

const EmptyPanel = ({ resourceName }) => (
  <Div className="empty-operation">{`Select a ${resourceName.toLowerCase()} to see more information about it`}</Div>
);

EmptyPanel.propTypes = {
  resourceName: PropTypes.string.isRequired
};

const FeatureId = 'resourceDetailView';
export const ResourceDetailPane = ({
  onDeleteClicked,
  onEditClicked,
  configuration,
  resource,
  modelId
}) => {
  const [properties, setProperties] = useState([]);
  const [resourceDisplayName, setResourceDisplayName] = useState(modelId);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configuration || !resource) {
      return cancelCallback;
    }

    const configProvider = FeatureConfigurationProvider.parse(FeatureId, configuration);
    const propertyOrders = configProvider.getValue('propertyOrders', 'array', ['vid', 'class']);

    const modelConfigProvider = configProvider.getModel(modelId);
    const propertyConfigMap = modelConfigProvider.getPropertyMap();

    const modelDisplayName = modelConfigProvider.getValue('displayName', 'string', modelId);
    const props = propertyOrders
      .filter((propertyId) => {
        return propertyConfigMap[propertyId];
      })
      .map((propertyId) => {
        const propertyConfig = propertyConfigMap[propertyId];
        const { displayName } = propertyConfig;
        const value = resource[propertyId] || '-';
        return {
          value,
          name: displayName,
          id: propertyId
        };
      });

    if (!cancelled) {
      setProperties(props);
      setResourceDisplayName(modelDisplayName);
    }

    return cancelCallback;
  }, [configuration, resource, modelId]);

  return (
    <Div className="resource-detail-pane">
      {resource ? (
        <Div className="resource-detail">
          <Div className="resource-detail-header">
            <Div className="resource-detail-header-title">{resource.name}</Div>
            <Div className="resource-detail-header-operations">
              <XButton
                onClick={onEditClicked}
                label="Edit"
                className="resource-operation-edit-button"
              />
              <XButton
                onClick={onDeleteClicked}
                label="Delete"
                className="resource-operation-delete-button"
              />
            </Div>
          </Div>
          <Div className="resource-detail-body">
            <Div className="resource-detail-body-descriptions">
              {properties.map((property) => (
                <Div className="resource-description-group" key={property.id}>
                  <Div className="resource-description-topic">{`${property.name}:`}</Div>
                  <Div className="resource-description-value">{property.value}</Div>
                </Div>
              ))}
            </Div>
          </Div>
        </Div>
      ) : (
        <EmptyPanel resourceName={resourceDisplayName} />
      )}
    </Div>
  );
};

ResourceDetailPane.defaultProps = {
  onEditClicked: () => {},
  onDeleteClicked: () => {},
  configuration: {},
  resource: null
};

ResourceDetailPane.propTypes = {
  onEditClicked: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  resource: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }),
  modelId: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    modelMap: PropTypes.objectOf(
      PropTypes.shape({
        displayName: PropTypes.string,
        propertyOrders: PropTypes.arrayOf(PropTypes.string),
        properties: PropTypes.objectOf(
          PropTypes.shape({
            displayName: PropTypes.string.isRequired
          })
        )
      })
    )
  })
};
