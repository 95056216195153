import React, { Fragment, Component } from 'react';
import './SearchBar.module.css';
import { InputGroup, Button } from 'react-bootstrap';
import { ListGroupItem, ListGroup } from 'mdbreact';
import TextareaAutosize from 'react-textarea-autosize';

export default class SearchBarAndResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchResults: this.props.searchResults
    };
  }

  componentWillMount() {
    this.setState({
      currentStyle: {
        ...this.state.currentStyle,
        [this.props.name]: null
      }
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.searchResults !== this.state.searchResults) {
      this.setState({
        searchResults: newProps.searchResults
      });
    }
  }

  getInputStyle(clicked) {
    const commonStyle = {
      overflow: 'hidden',
      lineHeight: 2,
      boxSizing: 'border-box',
      padding: 5,
      borderRadius: 5,
      resize: 'none'
    };

    const onBlurStyle = {
      ...commonStyle,
      width: '50%',
      borderColor: '#ced4da'
    };

    const errorStyle = {
      ...commonStyle,
      width: '50%',
      borderColor: 'red'
    };

    const onFocusStyle = {
      ...commonStyle,
      width: '50%',
      color: '#495057',
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)'
    };

    const onBlurSearchBarStyle = {
      ...commonStyle,
      width: '90%',
      borderColor: '#ced4da'
    };

    const onFocusSearchBarStyle = {
      ...commonStyle,
      width: '90%',
      color: '#495057',
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)'
    };

    const { isAutoResizable, inputNotValid } = this.props;
    if (isAutoResizable) {
      if (clicked) {
        return onFocusStyle;
      }
      if (inputNotValid) {
        return errorStyle;
      }
      return onBlurStyle;
    }
    if (clicked) {
      return onFocusSearchBarStyle;
    }
    return onBlurSearchBarStyle;
  }

  render() {
    const getSearchStyle = () => {
      const { searchResults, searchStyle } = this.props;
      if (searchResults.length > 0) {
        if (searchStyle === 'fixed') {
          return 'search-results-fixed';
        }
        return 'search-results-overlay';
      }
      return null;
    };

    const getSearchResults = () => {
      const { searchResults, searchFn, postAction } = this.props;
      return (
        <Fragment>
          <ListGroup className={getSearchStyle()}>
            {searchResults.map(result => {
              return (
                <ListGroupItem
                  dangerouslySetInnerHTML={{ __html: result.displayString }}
                  onClick={() => searchFn(result.id)}
                  key={result.id}
                />
              );
            })}
          </ListGroup>
          {postAction && getPostAction()}
        </Fragment>
      );
    };

    let getPostAction = () => {
      return (
        <ListGroup className={getSearchStyle()}>
          <ListGroupItem>{this.props.postAction()}</ListGroupItem>
        </ListGroup>
      );
    };
    const {
      searchFn,
      name,
      value,
      placeholder,
      handleInputChange,
      enableSearchButton,
      searchResults,
      label,
      styledTextField,
      containerStyle
    } = this.props;
    const { currentStyle } = this.state;
    const TextField = styledTextField || TextareaAutosize;
    return (
      <Fragment>
        <form
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault();
            searchFn();
          }}
        >
          <InputGroup>
            <TextField
              name={name}
              value={value}
              onFocus={event => {
                const style = this.getInputStyle(true);
                this.setState({
                  currentStyle: {
                    ...this.state.currentStyle,
                    [event.target.name]: style
                  }
                });
              }}
              onBlur={event => {
                this.setState({
                  currentStyle: {
                    ...this.state.currentStyle,
                    [event.target.name]: null
                  }
                });
              }}
              style={containerStyle || currentStyle[name] || this.getInputStyle(false)}
              placeholder={placeholder}
              variant="outlined"
              label={label}
              onChange={event => {
                handleInputChange(event.target.value);
              }}
              maxRows={this.props.isAutoResizable ? 3 : 1}
              wrap={this.props.isAutoResizable ? 'soft' : 'off'}
            />
            {enableSearchButton && (
              <InputGroup.Button>
                <Button type="button" id="searchbtn" className="btn-md" onClick={() => searchFn()}>
                  <i className="fa fa-search" />
                </Button>
              </InputGroup.Button>
            )}
          </InputGroup>
        </form>
        <div className="search-results-div">{searchResults && getSearchResults()}</div>
      </Fragment>
    );
  }
}