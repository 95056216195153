const getTierOneLocationConfiguration = ({
  categoryName = 'Plant',
  categoryDisplayName = 'Plant',
  categoryGroupDisplayName = 'Plants',
  canAttachDetector = false,
  propertyOrders = ['name', 'description'],
  containsLocationModel = null
}) => {
  return {
    category: {
      name: categoryName,
      displayName: categoryDisplayName,
      groupDisplayName: categoryGroupDisplayName
    },
    canAttachDetector,
    propertyOrders,
    containsLocationModel,
    properties: {
      name: {
        __addable: true,
        __updatable: true,
        displayName: `${categoryDisplayName} Name`
      },
      description: {
        __addable: true,
        __updatable: true,
        optional: true,
        displayName: `${categoryDisplayName} Description`
      }
    }
  };
};

const getTierTwoLocationConfiguration = ({
  categoryName = 'Department',
  categoryDisplayName = 'Department',
  categoryGroupDisplayName = 'Departments',
  canAttachDetector = false,
  propertyOrders = ['name', 'description'],
  locatedInModel = 'plant',
  overrideProperties = {}
}) => {
  return {
    category: {
      name: categoryName,
      displayName: categoryDisplayName,
      groupDisplayName: categoryGroupDisplayName
    },
    locatedInModel,
    canAttachDetector,
    propertyOrders,
    properties: {
      ...{
        name: {
          __addable: true,
          __updatable: true,
          displayName: `${categoryDisplayName} Name`
        },
        description: {
          __addable: true,
          __updatable: true,
          optional: true,
          displayName: `${categoryDisplayName} Description`
        }
      },
      ...overrideProperties
    }
  };
};

const modelMap = {
  facility: getTierOneLocationConfiguration({
    categoryName: 'Facility',
    categoryDisplayName: 'Facility',
    categoryGroupDisplayName: 'Facilities',
    canAttachDetector: false,
    containsLocationModel: 'demoDepartment'
  }),
  plant: getTierOneLocationConfiguration({
    categoryName: 'Plant',
    categoryDisplayName: 'Plant',
    categoryGroupDisplayName: 'Plants',
    canAttachDetector: false,
    containsLocationModel: 'department'
  }),
  HiscoCustomer: getTierOneLocationConfiguration({
    categoryName: 'Customer',
    categoryDisplayName: 'Customer',
    categoryGroupDisplayName: 'Customers',
    canAttachDetector: false
  }),
  HiscoPlant: getTierTwoLocationConfiguration({
    categoryName: 'Plant',
    categoryDisplayName: 'Plant',
    categoryGroupDisplayName: 'Plants',
    locatedInModel: 'HiscoCustomer',
    canAttachDetector: false
  }),
  demoDepartment: getTierTwoLocationConfiguration({
    categoryName: 'Department',
    categoryDisplayName: 'Department',
    categoryGroupDisplayName: 'Departments',
    locatedInModel: 'facility',
    canAttachDetector: true
  }),
  department: getTierTwoLocationConfiguration({
    categoryName: 'Department',
    categoryDisplayName: 'Department',
    categoryGroupDisplayName: 'Departments',
    locatedInModel: 'plant',
    canAttachDetector: true
  }),
  HiscoStockRoom: getTierTwoLocationConfiguration({
    categoryName: 'StockRoom',
    categoryDisplayName: 'Stock Room',
    categoryGroupDisplayName: 'Stock Rooms',
    parentLocationCategoryName: 'Plant',
    parentLocationCategoryDisplayName: 'Plant',
    parentLocationCategoryGroupDisplayName: 'Plants',
    canAttachDetector: true
  }),
  zone: getTierTwoLocationConfiguration({
    categoryName: 'Zone',
    categoryDisplayName: 'Zone',
    categoryGroupDisplayName: 'Zones',
    parentLocationCategoryName: 'Department',
    parentLocationCategoryDisplayName: 'Department',
    parentLocationCategoryGroupDisplayName: 'Departments',
    canAttachDetector: true
  }),
  detector: {
    displayName: 'Detector',
    propertyOrders: ['vid', 'class'],
    properties: {
      vid: {
        __addable: true,
        optional: true,
        optionallyDependsOn: ['class'],
        displayName: 'Detector #'
      },
      class: {
        __addable: true,
        optional: true,
        optionallyDependsOn: ['vid'],
        displayName: 'Detector Type',
        options: [
          { key: 'Handheld', value: 'Handheld Reader' },
          {
            key: 'Mounted',
            value: 'Mounted Reader',
            properties: {
              action: {
                displayName: 'Detector Actions',
                options: [
                  {
                    key: 'default_mounted_reader',
                    value: 'Default'
                  },
                  {
                    key: 'endTrackingSession',
                    value: 'Mark as Complete'
                  },
                  {
                    key: 'sameDetectorEndTrackingSession',
                    value: 'Entry / Exit doorway'
                  }
                ]
              }
            }
          }
        ]
      }
    }
  }
};

export const DefaultConfiguration = {
  modelMap,
  modelOrders: ['plant', 'department']
};
