import React from 'react';
import './InventoryRequestsPage.css';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import logo from '../../img/add_request_icon_white.png';
import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';
import InventoryRequestService from '../../services/InventoryRequestService';
import ConfigurationService from '../../services/ConfigurationService';
import NewRequest from '../../components/InventoryRequests/NewRequest';
import InventoryRequestComponet from '../../components/InventoryRequests/InventoryRequestComponent';
import ItemListComponent from '../../components/InventoryRequests/ItemListComponent';

// var mockList = require("./mockStockItems.json");

export default class InventoryRequestsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorMenu: [],
      newRequestMenu: [],
      requestDataList: null,
      selectedSideItem: null,
      stockItemList: [],
      dataList: [],
      vendorFilter: [],
      usersMap: [],
      allVendorsList: []
    };

    /* Uncomment this if you want to push all the stock item in to DB for keycompounding */

    // mockList.forEach((each)=>{
    //   InventoryRequestService.createStockItem(each);
    //
    // });
  }

  async componentDidMount() {
    this.buildOptionalColumnHeaderList();
    await this.getRequestDataList();
    await this.buildUserData();
    await this.getMockStockItemsList();
    await this.buildVendorMenu(this.state.requestDataList);
    await this.buildListData(this.state.requestDataList);
    let count = 0;
    this.state.newRequestMenu.forEach(each => {
      if (each.display) {
        count += 1;
      }
    });
    if (count > 0) {
      this.setState({
        selectedSideItem: {
          tagId: 'All New Requests',
          listId: 'new-requests'
        },
        defaultTab: 'New'
      });
    } else {
      this.setState({
        selectedSideItem: {
          tagId: 'All Vendors',
          listId: 'vendors'
        },
        defaultTab: null
      });
    }
  }

  buildOptionalColumnHeaderList = () => {
    // TODO: There is no getFullConfigurations() in ConfigurationService, tracing back couple months old history but still
    //  no lead to when getFullConfigurations() is removed. This code is commented for now future developers to either
    //  pick up my slack or to investigate why it was removed and left this code untested.
    // const { optionalColumnHeader } = ConfigurationService.getFullConfigurations();
    // this.setState({ optionalColumnHeader });
  };

  async buildUserData() {
    const authConfig = ConfigurationService.getAuthConfiguration();
    const credentials = await AuthService.retrieveEssentialCredentials();

    const config = {
      region: authConfig.Auth.region,
      userPoolId: authConfig.Auth.userPoolId,
      credentials
    };

    await UserService.configure(config);

    const usersMap = (await UserService.getUsers()).reduce((map, user) => {
      map[UserService.getUserId(user)] = {
        fullname: UserService.getFullName(user),
        username: UserService.getUsername(user)
      };
      return map;
    }, {});

    this.setState({ usersMap });
  }

  buildListData = requestDataList => {
    const dataList = [];
    const { optionalColumnHeader, usersMap } = this.state;
    requestDataList.forEach(each => {
      const data = {
        restock_request_id: each.restock_request_id,
        item_name: each.stock_item_name,
        order_number: each.order_number,
        quantity: each.restock_request_details[0].quantity,
        size: each.size,
        vendor: each.vendor,
        restock_requested_timestamp: each.restock_request_details[0].restock_requested_timestamp,
        restock_last_updated_timestamp:
          each.restock_request_details[0].restock_last_updated_timestamp,
        by: usersMap[each.restock_request_details[0].user_id]
          ? usersMap[each.restock_request_details[0].user_id].fullname
          : '-',
        status: each.status,
        comment: each.comment
      };

      if (optionalColumnHeader) {
        data.cost = each.cost;
      }
      dataList.push(data);
    });
    this.setState({ dataList });
  };

  updateComment = async (comment, restock_request_id) => {
    if (comment.length <= 0) {
      comment = null;
    }
    const updatedObject = await InventoryRequestService.updateRestockRequest(restock_request_id, {
      comment
    });
    const requestDataList = this.state.requestDataList;
    const index = requestDataList.findIndex(function(each) {
      return each.restock_request_id === restock_request_id;
    });
    requestDataList[index].comment = updatedObject.comment;
    this.buildVendorMenu(requestDataList);
    this.buildListData(requestDataList);
    this.setState({ requestDataList });
  };

  updateStatus = (status, time, restock_request_id) => {
    const requestDataList = _.cloneDeep(this.state.requestDataList);
    restock_request_id.forEach(eachId => {
      const index = requestDataList.findIndex(eachData => {
        return eachData.restock_request_id === eachId;
      });
      const detailPayload = _.cloneDeep(requestDataList[index].restock_request_details);
      detailPayload[0].restock_last_updated_timestamp = time;
      InventoryRequestService.updateRestockRequest(eachId, {
        status,
        restock_request_details: detailPayload
      });
      requestDataList[index].status = status;
      requestDataList[index].restock_request_details[0].restock_last_updated_timestamp = time;
    });
    this.buildVendorMenu(requestDataList);
    this.buildListData(requestDataList);
    this.setState({ requestDataList });
  };

  updateStockItem = (form, newStockItem) => {
    Object.keys(newStockItem).forEach(eachKey => {
      if (form[eachKey] && form[eachKey] !== newStockItem[eachKey]) {
        newStockItem[eachKey] = form[eachKey];
      }
    });
    const stockItemList = this.state.stockItemList;
    const index = stockItemList.findIndex(function(each) {
      return each.stock_item_id === newStockItem.stock_item_id;
    });
    if (
      stockItemList[index].size === newStockItem.size &&
      stockItemList[index].order_number === newStockItem.order_number &&
      stockItemList[index].vendor === newStockItem.vendor &&
      stockItemList[index].stock_item_name === newStockItem.stock_item_name
    ) {
      this.setState({ stockItemList });
    } else {
      stockItemList[index] = newStockItem;
      const payload = _.cloneDeep(newStockItem);
      const stock_item_id = payload.stock_item_id;
      delete payload.stock_item_id;
      InventoryRequestService.updateStockItem(stock_item_id, payload);
      this.setState({ stockItemList });
    }
  };

  addStockItem = async payload => {
    const newStockItem = await InventoryRequestService.createStockItem(payload);
    const stockItemList = this.state.stockItemList;
    stockItemList.push(newStockItem);
    this.setState({ stockItemList });
    return newStockItem.stock_item_id;
  };

  addRequests = async payload => {
    const requestDataList = _.cloneDeep(this.state.requestDataList);
    const newRequest = await InventoryRequestService.createRestockRequest(payload);
    requestDataList.push(newRequest);
    this.buildVendorMenu(requestDataList);
    this.buildListData(requestDataList);
    this.setState({ requestDataList });
  };

  deleteRequests = restock_request_id => {
    const requestDataList = _.cloneDeep(this.state.requestDataList);
    restock_request_id.forEach(eachId => {
      InventoryRequestService.deleteRestockRequest(eachId);
      const index = requestDataList.findIndex(eachData => {
        return eachData.restock_request_id === eachId;
      });
      requestDataList.splice(index, 1);
    });
    this.buildVendorMenu(requestDataList);
    this.buildListData(requestDataList);
    this.setState({ requestDataList });
  };

  editRequests = async editRequestsObject => {
    const requestDataList = _.cloneDeep(this.state.requestDataList);
    const stockItemList = _.cloneDeep(this.state.stockItemList);
    Object.keys(editRequestsObject).forEach(restock_request_id => {
      const index = requestDataList.findIndex(function(eachIndex) {
        return restock_request_id === eachIndex.restock_request_id;
      });
      const stock_item_id = requestDataList[index].stock_item_id;
      const stockPayload = {};
      const restock_request_details = requestDataList[index].restock_request_details;
      Object.keys(editRequestsObject[restock_request_id]).forEach(key => {
        if (key !== 'quantity') {
          stockPayload[key] = editRequestsObject[restock_request_id][key];
          requestDataList[index][key] = editRequestsObject[restock_request_id][key];
        } else {
          restock_request_details[0][key] = editRequestsObject[restock_request_id][key];
        }
      });

      const stockItemIndex = stockItemList.findIndex(function(eachIndex) {
        return stock_item_id === eachIndex.stock_item_id;
      });
      Object.keys(stockPayload).forEach(eachKey => {
        stockItemList[stockItemIndex][eachKey] = stockPayload[eachKey];
      });

      requestDataList[index].restock_request_details = restock_request_details;

      const restockPayload = stockPayload;
      restockPayload.restock_request_details = restock_request_details;

      InventoryRequestService.updateStockItem(stock_item_id, stockPayload);
      InventoryRequestService.updateRestockRequest(restock_request_id, restockPayload);
    });

    await this.setState({ requestDataList, stockItemList });
    await this.buildListData(requestDataList);
    await this.buildVendorMenu(requestDataList);
  };

  buildVendorMenu = requestDataList => {
    const mapVendor = {};
    const mapNew = {};

    requestDataList.forEach(eachRequest => {
      if (!mapVendor.hasOwnProperty(eachRequest.vendor)) {
        mapVendor[eachRequest.vendor] = 0;
      }
      mapVendor[eachRequest.vendor] += 1;

      if (eachRequest.status === 'New') {
        if (!mapNew.hasOwnProperty(eachRequest.vendor)) {
          mapNew[eachRequest.vendor] = 0;
        }
        mapNew[eachRequest.vendor] += 1;
      }
    });

    const vendorList = [];
    const newRequestMenuList = [];
    Object.keys(mapVendor).forEach(vendor => {
      let display = false;
      if (mapNew[vendor]) {
        display = true;
      }
      newRequestMenuList.push({ name: vendor, data: mapNew[vendor], display });
      vendorList.push({ name: vendor, data: mapVendor[vendor], display: true });
    });
    vendorList.sort((a, b) => a.name.localeCompare(b.name));
    newRequestMenuList.sort((a, b) => a.name.localeCompare(b.name));
    let list = [];
    vendorList.forEach(each => {
      list.push(each.name);
    });
    let { selectedSideItem, defaultTab } = this.state;
    if (selectedSideItem) {
      if (selectedSideItem.listId === 'new-requests') {
        if (newRequestMenuList.length <= 0) {
          selectedSideItem = {
            tagId: 'All Vendors',
            listId: 'vendors'
          };
        } else if (
          newRequestMenuList.findIndex(function(eachItem) {
            return eachItem.name === selectedSideItem.tagId && eachItem.display;
          }) === -1
        ) {
          defaultTab = null;
          if (
            vendorList.find(function(each) {
              return each.name === selectedSideItem.tagId;
            })
          ) {
            selectedSideItem.listId = 'vendors';
          } else if (selectedSideItem.tagId === 'All New Requests') {
            selectedSideItem = {
              tagId: 'All New Requests',
              listId: 'new-requests'
            };
            defaultTab = 'New';
          } else {
            selectedSideItem = {
              tagId: 'All Vendors',
              listId: 'vendors'
            };
          }
        } else {
          defaultTab = 'New';
        }
      } else if (
        !vendorList.find(function(each) {
          return each.name === selectedSideItem.tagId;
        })
      ) {
        defaultTab = null;
        selectedSideItem = {
          tagId: 'All Vendors',
          listId: 'vendors'
        };
      }
    }

    if (
      selectedSideItem &&
      selectedSideItem.tagId !== 'All Vendors' &&
      selectedSideItem.tagId !== 'All New Requests'
    ) {
      list = [selectedSideItem.tagId];
    }

    // added for all vendors
    const vlist = [];
    const itemsList = this.state.stockItemList;
    itemsList.forEach(item => {
      if (vlist.indexOf(item.vendor) === -1) {
        vlist.push(item.vendor);
      }
    });

    this.setState({
      defaultTab,
      selectedSideItem,
      vendorMenu: vendorList,
      newRequestMenu: newRequestMenuList,
      vendorFilter: list,
      allVendorsList: vlist
    });
  };

  getRequestDataList = async () => {
    const result = await InventoryRequestService.getAllRestockRequests();
    this.handleChange('requestDataList', result);
  };

  getMockStockItemsList = async () => {
    const result = await InventoryRequestService.getAllStockItems();
    this.handleChange('stockItemList', result);
  };

  handleChange = (id, value) => {
    this.setState({
      [id]: value
    });
  };

  addNewRequestButtonClicked = () => {
    this.setState({
      activeContent: 'new-request'
    });
  };

  cancelNewRequest = () => {
    this.setState({
      activeContent: 'requests',
      isCanceled: true
    });
  };

  renderAddRequestsButton = () => {
    return (
      <button className="add-request-button" onClick={this.addNewRequestButtonClicked}>
        <img src={logo} className="button-logo" alt="button-logo" />
        <div>Add New Request</div>
      </button>
    );
  };

  handleSelected = (selectedItem, vendors) => {
    this.cancelNewRequest();
    this.setState({
      selectedSideItem: selectedItem,
      vendorFilter: vendors,
      defaultTab: null,
      activeContent: null
    });
  };

  handleSelectedNew = (selectedItem, vendors) => {
    this.cancelNewRequest();
    this.setState({
      selectedSideItem: selectedItem,
      vendorFilter: vendors,
      defaultTab: 'New',
      activeContent: null
    });
  };

  filterVendor = () => {
    const { isCanceled, defaultTab, newRequestMenu, vendorFilter } = this.state;
    if (isCanceled && defaultTab === 'New') {
      const list = [];
      newRequestMenu.forEach(each => {
        if (each.display) {
          list.push(each.name);
        }
      });
      this.setState({ isCanceled: false });
      return list;
    }
    return vendorFilter;
  };

  render() {
    const {
      activeContent,
      stockItemList,
      usersMap,
      allVendorsList,
      dataList,
      vendorMenu,
      defaultTab,
      newRequestMenu,
      selectedSideItem,
      optionalColumnHeader
    } = this.state;
    const getDynamicContent = () => {
      switch (activeContent) {
        case 'new-request':
          return (
            <NewRequest
              stockItems={stockItemList}
              cancelRequestFn={this.cancelNewRequest}
              addRequests={this.addRequests}
              addStockItem={this.addStockItem}
              users={usersMap}
              updateStockItem={this.updateStockItem}
              vendors={allVendorsList}
              optionalColumnHeader={optionalColumnHeader}
            />
          );
        default:
          return (
            <InventoryRequestComponet
              dataList={dataList}
              selectedVendor={this.filterVendor()}
              vendorCount={vendorMenu.length}
              defaultTab={defaultTab}
              updateStatus={this.updateStatus}
              deleteRequests={this.deleteRequests}
              updateComment={this.updateComment}
              editRequests={this.editRequests}
              optionalColumnHeader={optionalColumnHeader}
            />
          );
      }
    };

    return (
      <div className="inventoryRequests-page">
        <div className="container1">
          {this.renderAddRequestsButton()}
          <Divider />
          <div className="container3">
            <ItemListComponent
              title="New Requests"
              options={newRequestMenu}
              listId="new-requests"
              selected={selectedSideItem}
              handleSelected={this.handleSelectedNew}
              showDigit
            />
          </div>
          <Divider />
          <div className="container3">
            <ItemListComponent
              title="Vendors"
              options={vendorMenu}
              listId="vendors"
              searchable
              selected={selectedSideItem}
              handleSelected={this.handleSelected}
              showDigit={false}
            />
          </div>
        </div>
        <div className="container2">{getDynamicContent()}</div>
      </div>
    );
  }
}
