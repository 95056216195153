import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InteractiveImageMapper } from '../interactive-image-mapper';
import { CustomPin } from '../interactable-pin';
import './style.css';

export const FloorPlan = ({
  imageUrl,
  pins,
  className,
  onImageLoaded,
  onImageClicked,
  onDimensionRatioChanged
}) => {
  const [translatedPinMap, setTranslatedPinMap] = useState({});
  const [ratioDimension, setRatioDimension] = useState({ dx: 1, dy: 1 });
  const [imageDimension, setImageDimension] = useState({
    width: 0,
    height: 0,
    fullWidth: 0,
    fullHeight: 0
  });

  // this flag is used to delay showing the pin until the map is fully loaded.
  //  This delay is important since the pin location is transformed based on the map displaying dimension.
  const [imageLoaded, setImageLoaded] = useState(false);
  const [pinResizeRequest, setPinResizeRequest] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [imageOffsetInfo, setImageOffsetInfo] = useState({ offsetTop: 0, offsetLeft: 0 });

  useEffect(() => {
    const { dx, dy } = ratioDimension;
    const pinMap = pins.reduce((map, pin) => {
      const clonedMap = { ...map };
      const { x, y, component, id, tooltip, name, pinType, color } = pin;

      const offsetInfo = imageOffsetInfo;
      const newX = x * dx + offsetInfo.offsetLeft;
      const newY = y * dy + offsetInfo.offsetTop;

      const transformedPin = { x: newX, y: newY, component, id, tooltip, name, pinType, color };
      clonedMap[id] = transformedPin;
      return clonedMap;
    }, {});

    setTranslatedPinMap(pinMap);
  }, [pins, ratioDimension, imageOffsetInfo]);

  useEffect(() => {
    onDimensionRatioChanged({ ...ratioDimension });
  }, [ratioDimension, onDimensionRatioChanged]);

  useEffect(
    () => {
      let cancelled = false;
      const cancelCallback = () => {
        cancelled = true;
      };

      if (!imageRef) {
        return cancelCallback;
      }

      const { offsetLeft, offsetTop } = imageRef;
      const { offsetLeft: registeredOffsetLeft, offsetTop: registeredOffsetTop } = imageOffsetInfo;

      // there has been no changes since the last time we measure, skip this effect
      if (offsetLeft === registeredOffsetLeft && offsetTop === registeredOffsetTop) {
        return cancelCallback;
      }

      if (!cancelled) {
        setImageOffsetInfo({ offsetLeft, offsetTop });
      }

      return cancelCallback;
    }, // this effect should be triggered [again] when image is successfully loaded or when image is resized.
    [imageRef, imageOffsetInfo, imageLoaded, pinResizeRequest]
  );

  // triggers when image is successfully loaded
  const onImageLoadedCallback = useCallback(
    (clientDimension, realDimension) => {
      const { width, height } = clientDimension;
      const { width: fullWidth, height: fullHeight } = realDimension;

      const widthRatio = width / fullWidth;
      const heightRatio = height / fullHeight;

      const ratio = { dx: widthRatio, dy: heightRatio };
      setRatioDimension(ratio);
      setImageLoaded(true);
      setImageDimension({ width, height, fullWidth, fullHeight });
      onImageLoaded(clientDimension, realDimension);
      onDimensionRatioChanged(ratio);
    },
    [onImageLoaded, onDimensionRatioChanged]
  );

  const onResizeCallback = useCallback(
    (clientDimension, realDimension) => {
      onImageLoadedCallback(clientDimension, realDimension);
      setPinResizeRequest(Date.now().toString());
    },
    [onImageLoadedCallback]
  );

  const style = `floor-plan-map ${className}`;

  return (
    <div className={style}>
      {imageLoaded &&
        Object.values(translatedPinMap).map((pin) => {
          const { x, y, component, id, tooltip, name, pinType, color } = pin;
          return (
            <CustomPin
              y={y}
              x={x}
              key={`${x}-${y}-${id}`}
              pinComponent={component}
              tooltip={tooltip}
              pinType={pinType}
              adjustTicket={pinResizeRequest}
              name={name}
              boundary={{ ...imageDimension, ...imageOffsetInfo }}
              nameReferencingColor={color}
            />
          );
        })}
      <InteractiveImageMapper
        imageRef={setImageRef}
        imageSrc={imageUrl}
        onLoad={onImageLoadedCallback}
        onClick={onImageClicked}
        onResize={onResizeCallback}
      />
    </div>
  );
};

FloorPlan.defaultProps = {
  pins: [],
  className: '',
  onImageLoaded: () => {},
  onImageClicked: () => {},
  onDimensionRatioChanged: () => {}
};

FloorPlan.propTypes = {
  pins: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      component: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      id: PropTypes.string.isRequired,
      tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      pinType: PropTypes.string,
      color: PropTypes.string,
      name: PropTypes.string
    })
  ),
  imageUrl: PropTypes.string.isRequired,
  onImageLoaded: PropTypes.func,
  onImageClicked: PropTypes.func,
  onDimensionRatioChanged: PropTypes.func,
  className: PropTypes.string
};
