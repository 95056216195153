import { ServiceConfigurationProvider } from './service-configuration-provider';
import { ModelConfigurationProvider } from './model-configuration-provider';
import { WebClientConfigurationProvider } from './web-client-configuration-provider';
import { BaseConfigurationProvider } from './base-configuration-provider';

const parseForGlobalModelDefinitionMap = (userConfig) => {
  const { modelMap } = userConfig;
  const modelProvider = ModelConfigurationProvider.parse(modelMap);
  return modelProvider.getModelDefinitionDomainMap();
};

/**
 *
 * @param userConfig
 * @returns { Object.<string, ServiceConfigurationProvider> }
 */
const parseForServiceConfigurationProviderMap = (userConfig) => {
  // TODO: infrastructure is for backward compatibility support
  const { infrastructure = {} } = userConfig || {};
  const { serviceMap = infrastructure } = userConfig || {};

  return Object.keys(serviceMap).reduce((map, serviceId) => {
    const configuration = serviceMap[serviceId];
    const serviceConfigProvider = ServiceConfigurationProvider.parse(serviceId, configuration);

    const newMap = { ...map };
    newMap[serviceId] = serviceConfigProvider;

    return newMap;
  }, {});
};

export class UserConfiguration extends BaseConfigurationProvider {
  constructor(configObj) {
    super(configObj);
    this.serviceConfigProviderMap = parseForServiceConfigurationProviderMap(configObj);
    this.modelDefinitionDomainMap = parseForGlobalModelDefinitionMap(configObj);

    const { webClient: webClientConfig = {} } = configObj;
    this.webClientConfigProvider = WebClientConfigurationProvider.parse(
      webClientConfig,
      this.modelDefinitionDomainMap
    );
  }

  /**
   * Refer to https://docs.google.com/document/d/1OcPxKXSpDqynXukP66snffGz08Gu98Qr5JCmBNdneRc for configuration structure
   * @param tierZeroConfig - tier-0 structure
   * @returns {UserConfiguration}
   */
  static parse(tierZeroConfig) {
    let configObj = tierZeroConfig;
    if (typeof tierZeroConfig === 'string') {
      configObj = JSON.parse(tierZeroConfig);
    }

    return new UserConfiguration(configObj);
  }

  /**
   * Retrieve service configuration provider given service id
   * @param serviceId
   * @returns { ServiceConfigurationProvider | null}
   */
  getServiceConfigurationProvider(serviceId) {
    return this.serviceConfigProviderMap[serviceId];
  }

  getServiceConfiguration(serviceId) {
    const configProvider = this.serviceConfigProviderMap[serviceId];

    if (!configProvider) {
      return null;
    }
    return configProvider.getConfiguration();
  }

  getWebClientConfigurationProvider() {
    return this.webClientConfigProvider;
  }

  getApps() {
    return this.webClientConfigProvider.getXemelgoApps();
  }

  getLandingPage() {
    return this.webClientConfigProvider.getLandingPage();
  }

  getAppConfigurationProvider(appId) {
    return this.webClientConfigProvider.getAppConfigurationProvider(appId);
  }

  /**
   *
   * @param appId
   * @param featureId
   * @param [defaultConfiguration] { object }
   * @returns { FeatureConfigurationProvider }
   */
  getFeatureConfigurationProvider(appId, featureId, defaultConfiguration = {}) {
    return this.webClientConfigProvider.getFeatureConfigurationProvider(
      appId,
      featureId,
      defaultConfiguration
    );
  }

  /**
   * Retrieve the sub-feature of a Feature. In order to access sub-feature, a path from app to feature must be provided
   * @param appId
   * @param featureId
   * @param subFeatureId
   * @returns { FeatureConfigurationProvider }
   */
  getSubFeatureConfigurationProvider(appId, featureId, subFeatureId) {
    const featureConfigProvider = this.getFeatureConfigurationProvider(appId, featureId);
    return featureConfigProvider.getSubFeatureConfigurationProvider(subFeatureId);
  }
}
