import React, { useState } from 'react';
import { MDBInput } from 'mdbreact';
import PropTypes from 'prop-types';
import { useStore } from 'react-hookstore';
import { loggingUserStore } from '../../../state-managements/stores/login-store';

const NewPasswordForm = ({ logo, onCancel, onSubmit }) => {
  const [loggingUser] = useStore(loggingUserStore);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValidForm, setIsFormValid] = useState(false);

  const onInputChangeNewPassword = event => {
    const password = event.target.value;
    setNewPassword(password);
  };

  const onInputChangeConfirmPassword = event => {
    const password = event.target.value;
    setConfirmPassword(password);

    setIsFormValid(password === newPassword && newPassword !== '' && newPassword.length >= 8);
  };

  return (
    <div className="content-body login-page-body">
      <div className="container login-form" style={{ textAlign: 'initial' }}>
        <form className="login-form-background">
          <img src={logo} className="brand-logo" alt="brand-logo" />

          <div className="form-group">
            <p
              style={{
                textAlign: 'center',
                color: 'white'
              }}
            >
              {`Welcome! Please enter a new password for your email - ${loggingUser}`}
            </p>
            <MDBInput
              className="input-default"
              label="New Password"
              id="newPassword"
              value={newPassword}
              onInput={onInputChangeNewPassword}
              type="password"
            />
            <MDBInput
              className="input-default"
              label="Confirm Password"
              id="forgotPasswordUsername"
              value={confirmPassword}
              onInput={onInputChangeConfirmPassword}
              type="password"
            />
          </div>
          <div className="profile-form-buttons">
            <button
              className="cancel-button change-password-button"
              type="button"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              disabled={!isValidForm}
              type="submit"
              onClick={e => {
                e.preventDefault();
                onSubmit(loggingUser, newPassword);
              }}
              className="default-button  change-password-button"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

NewPasswordForm.propTypes = {
  logo: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export { NewPasswordForm };
