import React from 'react';
import PropTypes from 'prop-types';
import { XButton } from '../../../../../components/xemelgo-button';
import { Div } from '../../../../../components/div';
import './style.css';

export const DeleteLocationFormFooter = ({ onCancel, onSubmit, className }) => {
  return (
    <Div className={`delete-location-form-footer ${className}`}>
      <XButton className="cancel-button" label="Cancel" onClick={onCancel} />
      <XButton className="confirm-button" label="Confirm Deletion" onClick={onSubmit} />
    </Div>
  );
};

DeleteLocationFormFooter.defaultProps = {
  className: '',
  onCancel: () => {},
  onSubmit: () => {}
};

DeleteLocationFormFooter.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};
