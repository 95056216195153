import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { ListedItem } from '../../../../../components/collapsible-list-view/listed-item';
import { Div } from '../../../../../components/div';

export const RecordRows = ({ resources, onSelectRecord, focus }) => {
  const [selectedId, setSelectedId] = useState(null);
  const onSelectRecordCallback = (id) => {
    setSelectedId(id);
    onSelectRecord(id);
  };

  useEffect(() => {
    if (!focus) {
      setSelectedId(null);
    }
  }, [focus]);

  return (
    <Fragment>
      {}
      {resources.map((resource) => {
        const { id, name } = resource;

        const styleClassName = `record-rows ${selectedId === id ? 'record-selected' : ''}`;

        return (
          <ListedItem
            className={styleClassName}
            content={<Div className="resource-name">{name}</Div>}
            key={id}
            onClickCallback={() => onSelectRecordCallback(id)}
          />
        );
      })}
    </Fragment>
  );
};

RecordRows.defaultProps = {
  resources: [],
  onSelectRecord: () => {},
  focus: false
};

RecordRows.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  ),
  onSelectRecord: PropTypes.func,
  focus: PropTypes.bool
};
