import React from 'react';

export const UnauthorizedPage = () => {
  return (
    <div>
      <div>Nice try, but you are not authorized to access this app!</div>
      <div>
        <a href="/">Click me</a>
{' '}
to return to home page.
</div>
    </div>
  );
};
