import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../../../../components/div';
import './style.css';
import { AddPinProcedure } from '../add-pin-procedure';
import { FloorPlan } from '../../../../../components/floor-plan';
import { GpsPinHead } from '../../../../../components/interactable-pin/gps-pin-head';
import { MissingImageBlock } from '../../../../../components/missing-image-block';

const buildFloorPlanPinPayload = (pin, scale) => {
  const { id, x, y, color, name } = pin;
  return {
    id,
    x,
    y,
    color,
    name,
    component: <GpsPinHead color={color} canClick={false} scale={10 * scale} />,
    tooltip: <Div>{name}</Div>
  };
};

export const InteractableLocationFloorPlan = ({
  imageUrl,
  pins,
  pinRequest,
  removePinRequest,
  onPinDrop
}) => {
  const [dropPoint, setDropPoint] = useState(null);
  const [floorPlanPinMap, setFloorPlanPinMap] = useState({});
  const [addPinMode, setAddPinMode] = useState(false);
  const [ratioDimension, setRatioDimension] = useState({ dx: 1, dy: 1 });

  useEffect(() => {
    setAddPinMode(!!pinRequest);
  }, [pinRequest]);

  /**
   * Name: removePin
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!removePinRequest) {
      return cancelCallback;
    }

    const { id } = removePinRequest;
    if (!cancelled) {
      setFloorPlanPinMap((existingMap) => {
        const clonedMap = { ...existingMap };
        delete clonedMap[id];
        return clonedMap;
      });
    }

    return cancelCallback;
  }, [removePinRequest]);

  /**
   * This effect construct the floor-plan pin payload from coordinate pin information.
   * This effect also depends on the image display dimension to full dimension ratio to calculate
   *  the size of the pin head.
   */
  useEffect(() => {
    const pMap = pins.reduce((map, pin) => {
      const clonedMap = { ...map };
      const { id } = pin;
      clonedMap[id] = buildFloorPlanPinPayload(pin, ratioDimension.dx);
      return clonedMap;
    }, {});

    setFloorPlanPinMap(pMap);
  }, [pins, ratioDimension]);

  const onProposingPinDrop = (e) => {
    if (!addPinMode) {
      return;
    }

    const { nativeEvent } = e;
    const mapX = nativeEvent.offsetX;
    const mapY = nativeEvent.offsetY;

    const { name, id } = pinRequest;
    const point = { x: mapX, y: mapY, name, id, dropPointId: Date.now().toString() };
    setDropPoint(point);
  };

  const onDimensionRatioChanged = useCallback((ratio) => {
    setRatioDimension(ratio);
  }, []);

  const onPinAdded = useCallback(
    (scaledCoordinatePin) => {
      // before we return the pin information, we must transform the pin coordinate to its full scale.
      const { dx, dy } = ratioDimension;
      const { x, y } = scaledCoordinatePin;
      const newX = (1.0 / dx) * x;
      const newY = (1.0 / dy) * y;
      const unscaledCoordinatePin = { ...scaledCoordinatePin, ...{ x: newX, y: newY } };

      // notify event listener for new pin dropped
      onPinDrop({ ...unscaledCoordinatePin });

      // drop point is the initial coordinate to start the add pin procedure, since we have done dropping the pin,
      //  we can set the drop point back to null state
      setDropPoint(null);
    },
    [ratioDimension, onPinDrop]
  );

  const onColorPickCancelled = useCallback((failedDropPoint) => {
    setDropPoint({ ...failedDropPoint, dropPointId: Date.now().toString() });
  }, []);

  return (
    <Div className="add-pin-form">
      {dropPoint && (
        <AddPinProcedure
          name={dropPoint.name}
          x={dropPoint.x}
          y={dropPoint.y}
          id={dropPoint.id}
          onPinAdded={onPinAdded}
          onColorPickCancelled={onColorPickCancelled}
          requestTicket={dropPoint.dropPointId}
        />
      )}
      {imageUrl && (
        <FloorPlan
          className={addPinMode ? 'adding-pin' : ''}
          imageUrl={imageUrl}
          pins={Object.values(floorPlanPinMap)}
          onImageClicked={onProposingPinDrop}
          onDimensionRatioChanged={onDimensionRatioChanged}
        />
      )}
      {!imageUrl && (
        <Div className="no-image-block">
          <MissingImageBlock className="no-floor-plan-image" width="2.5em" height="2.5em" />
          <Div className="no-floor-plan-message">No floor plan available</Div>
        </Div>
      )}
    </Div>
  );
};

InteractableLocationFloorPlan.defaultProps = {
  onPinDrop: () => {},
  pins: [],
  pinRequest: null,
  removePinRequest: null,
  imageUrl: null
};

InteractableLocationFloorPlan.propTypes = {
  pins: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  ),
  onPinDrop: PropTypes.func,
  pinRequest: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  removePinRequest: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  imageUrl: PropTypes.string
};
