import React, { Fragment } from 'react';
import { Card, Input } from 'mdbreact';
import './InventoryRequestComponent.css';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Calendar from 'react-calendar';
import Moment from 'react-moment';
import _ from 'lodash';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import SummaryTileList from './SummaryTileList';

const borderStyleMap = {
  Arriving: 'border-yellow',
  Arrived: 'border-green',
  New: 'border-blue',
  Backorder: 'border-red'
};

const backgroundStyleMap = {
  Arriving: 'background-yellow',
  Arrived: 'background-green',
  New: 'background-blue',
  Backorder: 'background-red'
};

const summaryTileMap = {
  New: 'New Requests',
  Backorder: 'Backorder',
  Arriving: 'Arriving By...',
  Arrived: 'Arrived'
};

export default class InventoryRequestComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tileData: [],
      checked: [],
      checkAll: false,
      dataList: this.props.dataList,
      filteredList: [],
      displayList: [],
      menu: false,
      selectedDate: null,
      statusList: [],
      showDeleteModal: false,
      enableEdit: false,
      editRequestsObject: {}
    };
  }

  componentWillReceiveProps(newProps) {
    const list = this.buildFilteredData(
      newProps.selectedVendor,
      newProps.dataList,
      newProps.vendorCount,
      newProps.defaultTab
    );
    list.sort(function(a, b) {
      return b.restock_requested_timestamp - a.restock_requested_timestamp;
    });
    this.setState({
      tileData: this.buildTileData(
        newProps.selectedVendor,
        newProps.dataList,
        newProps.vendorCount
      ),
      dataList: this.props.dataList,
      filteredList: list,
      displayList: list,
      checked: [],
      checkAll: false
    });
  }

  componentDidMount() {
    const { selectedVendor, dataList, vendorCount, defaultTab } = this.props;
    const list = this.buildFilteredData(selectedVendor, dataList, vendorCount, defaultTab);
    list.sort(function(a, b) {
      return b.restock_requested_timestamp - a.restock_requested_timestamp;
    });
    this.setState({
      tileData: this.buildTileData(selectedVendor, dataList, vendorCount),
      dataList,
      filteredList: list,
      displayList: list
    });
  }

  buildFilteredData = (filterList, dataList, vendorCount, defaultTab) => {
    let newFilteredList = [];

    if (filterList.length === 0) {
      return [];
    }
    if (filterList.length === vendorCount) {
      newFilteredList = _.cloneDeep(dataList);
    } else {
      dataList.forEach(each => {
        filterList.forEach(eachVendor => {
          if (each.vendor === eachVendor) {
            newFilteredList.push(each);
          }
        });
      });
    }
    return newFilteredList;
  };

  buildTileData = (filterList, dataList, vendorCount) => {
    let newFilteredList = [];
    if (filterList.length === 0) {
      return [];
    }
    if (filterList.length === vendorCount) {
      newFilteredList = _.cloneDeep(dataList);
    } else {
      dataList.forEach(each => {
        filterList.forEach(eachVendor => {
          if (each.vendor === eachVendor) {
            newFilteredList.push(each);
          }
        });
      });
    }

    const tempData = {};
    newFilteredList.forEach(each => {
      if (tempData[each.status]) {
        tempData[each.status].count += 1;
      } else {
        tempData[each.status] = { status: each.status, count: 1 };
      }
    });
    const summaryTileData = [];
    Object.keys(summaryTileMap).forEach(each => {
      Object.keys(tempData).forEach(eachKey => {
        if (each === eachKey) {
          summaryTileData.push({
            title: summaryTileMap[each],
            totalOrders: tempData[eachKey].count,
            status: tempData[eachKey].status
          });
        }
      });
      if (
        summaryTileData.length <= 0 ||
        summaryTileData[summaryTileData.length - 1].status !== each
      ) {
        summaryTileData.push({ title: summaryTileMap[each], totalOrders: 0, status: each });
      }
    });
    return summaryTileData;
  };

  handleToggleSelectAll = () => {
    const { checkAll } = this.state;
    const newChecked = [];
    if (!checkAll) {
      this.state.displayList.forEach(each => {
        newChecked.push(each.restock_request_id);
      });
    }
    this.setState({ checked: newChecked, checkAll: !checkAll });
  };

  handleToggle = value => () => {
    const { checked, editRequestsObject } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      delete editRequestsObject[value];
    }

    if (newChecked.length === 0) {
      this.setState({ enableEdit: false, editRequestsObject: {} });
    }

    this.setState({
      checked: newChecked,
      checkAll: newChecked.length === this.state.filteredList.length
    });
  };

  filterFunction = statusList => {
    if (statusList.length === 0) {
      this.setState({ displayList: [] });
    } else if (statusList.length === 5) {
      this.setState({ displayList: this.state.filteredList });
    } else {
      const newDataList = [];
      this.state.filteredList.forEach(each => {
        statusList.forEach(eachStatus => {
          if (each.status === eachStatus) {
            newDataList.push(each);
          }
        });
      });
      this.setState({ statusList, displayList: newDataList });
    }
  };

  renderCheckBox = value => {
    return (
      <div className="checkbox-outer">
        <div
          className={
            this.state.checked.indexOf(value) !== -1 ? 'checkbox-checked' : 'checkbox-unchecked'
          }
        />
      </div>
    );
  };

  renderListLabel = () => {
    return (
      <div className="list-item-title">
        <div className="item-small" onClick={this.handleToggleSelectAll}>
          <div className="checkbox-outer">
            <div className={this.state.checkAll ? 'checkbox-checked' : 'checkbox-unchecked'} />
          </div>
        </div>
        <div className="item-large item-label">Item Name</div>
        <div className="item-medium item-label">Order #</div>
        <div className="item-small item-label">Qty.</div>
        <div className="item-medium item-label">Size</div>
        <div className="item-medium item-label">Vendor</div>
        {this.hasOptionalColumnHeader('Cost') ? (
          <div className="item-medium item-label">Cost</div>
        ) : null}
        <div className="item-medium item-label">Request Date</div>
        <div className="item-large-name item-label">By</div>
        <div className="item-large item-label">Status</div>
        <div className="item-large item-label">Comments</div>
      </div>
    );
  };

  handleChangeStatus = event => {
    this.setState({ actionClicked: event.target.id });
  };

  handleSubmitStatus = () => {
    const { selectedDate, actionClicked, checked } = this.state;
    if (selectedDate) {
      this.props.updateStatus(actionClicked, selectedDate, checked);
      this.setState({
        checked: [],
        actionClicked: null,
        selectedDate: null,
        menu: false,
        checkAll: false
      });
    }
  };

  handleDeleteClick = () => {
    // Information to be displayed
    const { displayList, checked } = this.state;
    const { deleteRequests } = this.props;
    const arrangedDisplayInfo = {};
    let counter = 1;
    displayList.forEach(displayItem => {
      checked.forEach(value => {
        if (displayItem.restock_request_id === value) {
          arrangedDisplayInfo[`Item ${counter}`] = displayItem.item_name;
          counter++;
        }
      });
    });

    // rowData
    const data = {
      display_info: arrangedDisplayInfo,
      delete_info: checked,
      confirm_function: deleteRequests
    };

    this.setState({ showDeleteModal: true, rowData: data });
  };

  showDeleteModal = () => {
    // To display the confirmation message
    const { rowData } = this.state;
    return (
      <ConfirmDeleteModal
        rowData={rowData}
        headerName="Request"
        cancel_function={this.cancelDeleteModal}
      />
    );
  };

  cancelDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  toggleMenu = event => {
    if (event.target.id.length === 0) {
      this.setState({ actionClicked: null, selectedDate: null, menu: false });
    } else if (event.target.id === 'menu') {
      this.setState({ actionClicked: null, selectedDate: new Date(), menu: !this.state.menu });
    } else {
      this.setState({ menu: true });
    }
  };

  toggleComments = event => {
    const { updatedComment, commentMenuId } = this.state;
    if (event.target.id.length === 0) {
      if (updatedComment !== null) {
        this.props.updateComment(updatedComment, commentMenuId);
      }
      this.setState({ commentMenuId: null, updatedComment: null });
    } else {
      if (commentMenuId && event.target.id !== commentMenuId) {
        if (updatedComment !== null) {
          this.props.updateComment(updatedComment, commentMenuId);
        }
      }
      this.setState({ updatedComment: null, commentMenuId: event.target.id });
    }
  };

  changeDate = date => {
    this.setState({ selectedDate: date });
  };

  cancelAll = () => {
    this.setState({ checked: [], checkAll: false, editRequestsObject: {} });
  };

  handleEditClick = () => {
    this.setState({
      enableEdit: true
    });
  };

  cancelEdit = () => {
    this.setState({
      enableEdit: false,
      editRequestsObject: {}
    });
  };

  /* Saves the changes made after editing the request */
  saveEdit = () => {
    const { editRequestsObject } = this.state;
    this.props.editRequests(editRequestsObject);
    this.setState({
      enableEdit: false,
      editRequestsObject: {}
    });
  };

  renderEditActionBar = () => {
    if (this.state.checked.length > 0) {
      return (
        <Card className="action-bar">
          <div className="action-large delete-request" onClick={this.cancelEdit}>
            Cancel
          </div>
          <div className="action-large change-status" onClick={this.saveEdit}>
            Save
          </div>
        </Card>
      );
    }
  };

  renderActionBar = () => {
    const { checked, menu, actionClicked, selectedDate, showDeleteModal } = this.state;
    if (checked.length > 0) {
      return (
        <Card className="action-bar">
          <div className="action-xlarge bold close" onClick={this.cancelAll}>x
          </div>
          <div className="action-small number">{checked.length}</div>
          <div className="action-small">requests selected</div>
          <Dropdown direction="up" isOpen={menu} toggle={this.toggleMenu}>
            <DropdownToggle id="menu" tag="span" caret className="change-status action-large">
              Change Status
            </DropdownToggle>
            <DropdownMenu modifiers={null}>
              {actionClicked ? (
                <Fragment>
                  <Calendar onChange={this.changeDate} value={selectedDate} className="calendar" />
                  <div className="calendar-button-group">
                    <div className="calendar-button cancel" onClick={this.toggleMenu}>
                      Cancel
                    </div>
                    <div
                      className={
                        selectedDate ? 'calendar-button submit' : 'calendar-button submit-disabled'
                      }
                      onClick={this.handleSubmitStatus}
                    >
                      Submit
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <DropdownItem id="Backorder" onClick={this.handleChangeStatus}>
                    Backorder
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem id="Arriving" onClick={this.handleChangeStatus}>
                    Arriving
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem id="Arrived" onClick={this.handleChangeStatus}>
                    Arrived
                  </DropdownItem>
                </Fragment>
              )}
            </DropdownMenu>
          </Dropdown>
          <div className="action-large edit-request" onClick={this.handleEditClick}>
            Edit Request
          </div>
          <div className="action-large delete-request" onClick={this.handleDeleteClick}>
            Delete Request
          </div>
          {showDeleteModal && this.showDeleteModal()}
        </Card>
      );
    }
  };

  showComments = comments => {
    if (comments && comments.length > 20) {
      return `${comments.substring(0, 20)}...`;
    }
    return comments;
  };

  handleCommentChange = event => {
    this.setState({ updatedComment: event.target.value });
  };

  handleEditChange = (id, value, restockId) => {
    const { editRequestsObject } = this.state;
    if (!editRequestsObject[restockId]) {
      editRequestsObject[restockId] = {};
    }
    editRequestsObject[restockId][id] = value;
    this.setState({ editRequestsObject });
  };

  renderEditInput = (attrName, hint, restockId) => {
    return (
      <Input
        disabled={!this.state.enableEdit}
        value={this.state[attrName]}
        hint={hint}
        className="input-text"
        id={attrName}
        onInput={event => {
          attrName === 'quantity' || attrName === 'cost'
            ? this.handleEditChange(event.target.id, Number(event.target.value), restockId)
            : this.handleEditChange(event.target.id, event.target.value, restockId);
        }}
        style={{
          width: '100%',
          backgroundColor: 'white',
          borderRadius: 5,
          fontSize: 12,
          textAlign: 'left',
          padding: 5
        }}
      />
    );
  };

  canEditRequest = value => {
    const { enableEdit, checked } = this.state;
    const isChecked = checked.indexOf(value);
    return isChecked !== -1 && enableEdit;
  };

  hasOptionalColumnHeader = columnHeader => {
    const { optionalColumnHeader } = this.props;
    return optionalColumnHeader && optionalColumnHeader.indexOf(columnHeader) !== -1;
  };

  render() {
    const { defaultTab, selectedVendor } = this.props;
    const { tileData, displayList, checked, commentMenuId, enableEdit } = this.state;
    return (
      <Fragment>
        <div className="title-inventory">Inventory Request</div>
        <SummaryTileList
          defaultTab={defaultTab}
          tileData={tileData}
          filterFunction={this.filterFunction}
          selectedVendor={selectedVendor}
        />
        <Card className="list-card">
          {this.renderListLabel()}
          {displayList.map(value => {
            const canEdit = this.canEditRequest(value.restock_request_id);
            return (
              <Card
                className={`list-item ${borderStyleMap[value.status]} ${
                  checked.indexOf(value.restock_request_id) !== -1 ? 'list-item-checked' : ''
                }`}
                key={value.restock_request_id}
              >
                <div className="item-small" onClick={this.handleToggle(value.restock_request_id)}>
                  {this.renderCheckBox(value.restock_request_id)}
                </div>
                <div className={canEdit ? 'edit-box-large' : 'item-large'}>
                  {canEdit
                    ? this.renderEditInput(
                        'stock_item_name',
                        value.item_name,
                        value.restock_request_id
                      )
                    : value.item_name}
                </div>
                <div className={canEdit ? 'edit-box-medium' : 'item-medium'}>
                  {canEdit
                    ? this.renderEditInput(
                        'order_number',
                        value.order_number,
                        value.restock_request_id
                      )
                    : value.order_number}
                </div>
                <div className={canEdit ? 'edit-box-small' : 'item-small'}>
                  {canEdit
                    ? this.renderEditInput('quantity', value.quantity, value.restock_request_id)
                    : value.quantity}
                </div>
                <div className={canEdit ? 'edit-box-small' : 'item-medium'}>
                  {canEdit
                    ? this.renderEditInput('size', value.size, value.restock_request_id)
                    : value.size}
                </div>
                <div className={canEdit ? 'edit-box-medium' : 'item-medium'}>
                  {canEdit
                    ? this.renderEditInput('vendor', value.vendor, value.restock_request_id)
                    : value.vendor}
                </div>
                {this.hasOptionalColumnHeader('Cost') ? (
                  <div className={canEdit ? 'edit-box-small' : 'item-medium'}>
                    {canEdit
                      ? this.renderEditInput('cost', value.cost, value.restock_request_id)
                      : value.cost
                      ? `$ ${Number(value.cost).toFixed(2)}`
                      : '-'}
                  </div>
                ) : null}
                <div className="item-medium">
                  <Moment format="MMM DD, YYYY">{value.restock_requested_timestamp}</Moment>
                </div>
                <div className="item-large-name">{value.by}</div>
                <div className="item-medium">
                  <div className={'status '.concat(backgroundStyleMap[value.status])}>
                    <div>{value.status}</div>
                    <div>
                      {value.status !== 'New' ? (
                        <Moment format="MMM DD, YYYY">
                          {value.restock_last_updated_timestamp}
                        </Moment>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="item-large comments-group">
                  <Dropdown
                    direction="up"
                    isOpen={commentMenuId === value.restock_request_id}
                    toggle={this.toggleComments}
                  >
                    <DropdownToggle
                      id={value.restock_request_id}
                      tag="span"
                      className="comments"
                      value={value.comment}
                    >
                      {this.showComments(value.comment)}
                    </DropdownToggle>
                    <DropdownMenu className="menu-box" modifiers={null}>
                      <textarea
                        autoFocus
                        rows="8"
                        defaultValue={value.comment}
                        className="input-field"
                        onChange={event => {
                          this.setState({ updatedComment: event.target.value });
                        }}
                      />
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Card>
            );
          })}
          {enableEdit ? this.renderEditActionBar() : this.renderActionBar()}
        </Card>
      </Fragment>
    );
  }
}
