import React from 'react';
import { Route, Switch } from 'react-router';
import PropTypes from 'prop-types';

export const RoutableView = ({ partialViews }) => {
  return (
    <Switch>
      {partialViews.map((viewInfo) => {
        const { href, component, id } = viewInfo;

        return (
          <Route path={href} key={id}>
            {component}
          </Route>
        );
      })}
    </Switch>
  );
};

RoutableView.propTypes = {
  partialViews: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired
    })
  ).isRequired
};
