import Amplify, { API } from 'aws-amplify';
import axios from 'axios';
import AuthService from './AuthService';

function getAuthorizationHeader() {
  const sessionInfo = AuthService.getSessionInfo();
  if (!sessionInfo || !sessionInfo.tokens || !sessionInfo.tokens.idToken) {
    return null;
  }

  const headers = {
    Authorization: sessionInfo.tokens.idToken.jwtToken
  };

  return headers;
}

/**
 * this function wrap around the API function call. If the first call fails due to authorized exception, the wrapper will
 *  attempt to refresh the token and retry the API call.
 * @param fn
 * @return {Promise<*|void|Promise<T | never>>}
 */
async function executeWithValidAuthorizedToken(fn) {
  const promise = fn();

  return promise.catch(async err => {
    const response = err.response;
    if ((response && response.status === 401) || err.message === 'Network Error') {
      // the latter was due to AWS Amplify swallow the status and throw exception instead
      await AuthService.refreshToken();
      return await fn();
    }
    throw err;
  });
}

const RestServiceConfigurationKey = 'RestService_configurationKey';
function retrieveConfigurationFromSession() {
  const configurationString = sessionStorage.getItem(RestServiceConfigurationKey);
  if (configurationString) {
    return JSON.parse(configurationString);
  }

  return null;
}

function joinPath(root, path) {
  let rootPath = root;
  if (rootPath.endsWith('/')) {
    rootPath = rootPath.substring(0, rootPath.length - 1).trim();
  }

  let relativePath = path;
  if (relativePath.startsWith('/')) {
    relativePath = relativePath.substring(1).trim();
  }

  return `${rootPath}/${relativePath}`;
}

class RestService {
  constructor() {
    const config = retrieveConfigurationFromSession();
    if (config) {
      this.configure(config, false);
    }
  }

  /**
     * register configuration
     * @param config
     * {
     *     API: {
                endpoints: [
                    {
                        name: string,
                        endpoint: string,
                        region: string
                    },
                ]
            },
            imageBucket: {
                region: string,
                endpoint: string
            }
     *
     * }
     * @param cacheConfig
     */
  configure(config, cacheConfig = true) {
    if (!config) {
      return;
    }

    const { API } = { ...config };
    Amplify.configure(API);

    if (cacheConfig) {
      sessionStorage.setItem(RestServiceConfigurationKey, JSON.stringify(config));
    }
  }

  post(path, payload) {
    return executeWithValidAuthorizedToken(async () => {
      const promise = API.post('xemelgo', path, {
        body: payload,
        headers: await getAuthorizationHeader()
      });

      return promise;
    });
  }

  patch(path, payload) {
    return executeWithValidAuthorizedToken(async () => {
      const promise = API.patch('xemelgo', path, {
        body: payload,
        headers: await getAuthorizationHeader()
      });

      return promise;
    });
  }

  put(path, payload) {
    return executeWithValidAuthorizedToken(async () => {
      const promise = API.put('xemelgo', path, {
        body: payload,
        headers: await getAuthorizationHeader()
      });

      return promise;
    });
  }

  get(path) {
    return executeWithValidAuthorizedToken(async () => {
      const promise = API.get('xemelgo', path, {
        headers: await getAuthorizationHeader()
      });

      return promise;
    });
  }

  delete(path) {
    return executeWithValidAuthorizedToken(async () => {
      const promise = API.del('xemelgo', path, {
        headers: await getAuthorizationHeader()
      });

      return promise;
    });
  }

  async uploadImage(path, data, fileType) {
    const config = retrieveConfigurationFromSession();
    const { imageBucket, API } = { ...config };

    const endpoint = API.endpoints[0].endpoint;
    const url = joinPath(endpoint, path);
    const imageHeader = {
      headers: {
        'content-type': fileType
      }
    };

    return axios.post(url, data, imageHeader).then(result => {
      const img = result.data;
      const url = joinPath(imageBucket.endpoint, img.image_url);

      return { ...result, url };
    });
  }
}

export default new RestService();
