import React, { useState } from 'react';
import { NavItem } from 'reactstrap';
import PropTypes from 'prop-types';
import Style from '../CollapsibleListView.module.css';

export const ListedItem = ({
  content,
  onHoverCallback,
  onMouseOutCallback,
  onClickCallback,
  className
}) => {
  const [hovered, setHovered] = useState(false);
  const onHovered = () => {
    onHoverCallback();
    setHovered(true);
  };

  const onMouseOut = () => {
    onMouseOutCallback();
    setHovered(false);
  };

  const styleClass = hovered ? Style.level_view_active : Style.level_view_items;

  return (
    <NavItem
      className={`${styleClass} ${className}`}
      onMouseOver={onHovered}
      onFocus={() => setHovered(true)}
      onMouseOut={onMouseOut}
      onBlur={() => setHovered(false)}
      onClick={onClickCallback}
    >
      {content}
    </NavItem>
  );
};

ListedItem.defaultProps = {
  onHoverCallback: () => {},
  onMouseOutCallback: () => {},
  onClickCallback: () => {},
  className: ''
};

ListedItem.propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onHoverCallback: PropTypes.func,
  onClickCallback: PropTypes.func,
  onMouseOutCallback: PropTypes.func
};
