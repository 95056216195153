import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import './style.css';
import { XButton } from '../xemelgo-button';

export const ColorPicker = ({
  onCancel,
  show,
  offsetLeft,
  offsetTop,
  onSave,
  color,
  showCancel
}) => {
  const [selectColor, setSelectColor] = useState(color);

  const onSaveCallback = useCallback(() => {
    onSave(selectColor);
  }, [onSave, selectColor]);

  const onCancelCallback = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const popover = {
    position: 'absolute',
    zIndex: '5',
    left: offsetLeft,
    top: offsetTop
  };

  const onChangeComplete = useCallback((c) => {
    setSelectColor(c.hex);
  }, []);

  return (
    <div>
      {show ? (
        <div style={popover} className="color-picker">
          <ChromePicker onChangeComplete={onChangeComplete} color={selectColor} />
          <div className="color-picker-operations" style={{ zIndex: '5' }}>
            <XButton label="Save" className="color-picker-save-button" onClick={onSaveCallback} />
            {showCancel && (
              <XButton
                label="Cancel"
                className="color-picker-cancel-button"
                onClick={onCancelCallback}
              />
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

ColorPicker.defaultProps = {
  onCancel: () => {},
  onSave: () => {},
  show: true,
  offsetLeft: 0,
  offsetTop: 0,
  color: '#000000',
  showCancel: false
};

ColorPicker.propTypes = {
  color: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  show: PropTypes.bool,
  offsetLeft: PropTypes.number,
  offsetTop: PropTypes.number,
  showCancel: PropTypes.bool
};
