const UsernameKey = 'LoginService_username';

function loadUsernameFromCache() {
  return sessionStorage.getItem(UsernameKey);
}

class LoginService {
  /**
   * Register username to create a stateful session
   * @param username
   */
  registerUsername(username) {
    this.username = username;
    sessionStorage.setItem(UsernameKey, this.username);
  }

  isCustomerAccountRegistered() {
    if (this.username) {
      return true;
    }

    return loadUsernameFromCache();
  }
}

export default new LoginService();
