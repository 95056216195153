import { ModelConfigurationProvider } from './model-configuration-provider';
import { BaseConfigurationProvider } from './base-configuration-provider';
import { ModelDefinition } from './model-definition';

export class FeatureConfigurationProvider extends BaseConfigurationProvider {
  constructor(featureId, configObj, baseDomainMap) {
    super(configObj);
    this.featureId = featureId;
    this.config = configObj;

    const { modelMap } = configObj;
    const modelConfigurationProvider = ModelConfigurationProvider.parse(modelMap, baseDomainMap);
    const domainMap = modelConfigurationProvider.getModelDefinitionDomainMap();

    const completeModelMapConfiguration = Object.keys(domainMap).reduce((map, modelId) => {
      const clonedMap = { ...map };
      const model = domainMap[modelId];
      clonedMap[modelId] = model.getDefinitionObject();
      return clonedMap;
    }, {});

    const completeConfiguration = { ...configObj, modelMap: completeModelMapConfiguration };
    this.completeConfiguration = completeConfiguration;
    this.domainMap = domainMap;
  }

  static parse(featureId, configuration, baseDomainMap) {
    let configObj = configuration || {};
    if (typeof configuration === 'string') {
      configObj = JSON.parse(configuration);
    }

    return new FeatureConfigurationProvider(featureId, configObj, baseDomainMap);
  }

  getFeatureId() {
    return this.featureId;
  }

  getModelMap() {
    return this.domainMap;
  }

  /**
   * Return particular model
   * @param modelId
   * @returns {ModelDefinition}
   */
  getModel(modelId) {
    const model = this.domainMap[modelId];
    if (!model) {
      return new ModelDefinition(modelId, {});
    }
    return model;
  }

  /**
   * @param subFeatureId
   * @returns {null|FeatureConfigurationProvider}
   */
  getSubFeatureConfigurationProvider(subFeatureId) {
    const { featureMap: subFeatureMap = {} } = this.config;
    const subFeatureConfigObj = subFeatureMap[subFeatureId] || {};
    return new FeatureConfigurationProvider(subFeatureId, subFeatureConfigObj, this.domainMap);
  }

  getFeatureConfiguration(featureId) {
    const { featureMap: subFeatureMap = {} } = this.config;
    const subFeatureConfigObj = subFeatureMap[featureId] || {};
    const subFeatureProvider = new FeatureConfigurationProvider(
      featureId,
      subFeatureConfigObj,
      this.domainMap
    );
    return subFeatureProvider.getConfiguration();
  }

  getConfiguration() {
    return { ...this.completeConfiguration };
  }
}
